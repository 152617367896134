<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/payment-info">Payment Info</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <div>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Payment Info
                            </h1>
                            <h2 class="subtitle">
                                View and Update Payment Info.
                            </h2>
                        </div>
                    </div>
                </section>
                <div class="block"></div>
                <div class="block">
                    To update Paypal payment info, please submit your new email for payment via the form below, or email us at contact@cartogro.com, and we will contact you to confirm.  Alternatively please email us to discuss alternative payment methods.
                  </div>
                  <div class="block">
                    <b-field label="Paypal Address:">
                      {{paymentPpText()}}
                    </b-field>
                    
                  </div>

                  <div class="block">
                    <b-field label="Commission Value:">
                      {{commissionValue()}}%
                    </b-field>
                    
                  </div>
                <b-field label="Update PayPal payment email to:">
            <b-input  style="width: 400px;" v-model="paymentPp"></b-input>
            <b-button style="margin-left:10px" type="is-primary" @click="submitMessage" :class="{'is-loading' : isSending}">Request Update</b-button>
        </b-field>
                </div>

  </div>
</template>


<script>
 //import { PostWithAuth, GetWithAuth } from '../services/apiClient';
 //import Contact from '../components/Contact.vue'
   import { PostWithAuth, TrackEvent } from '../services/apiClient';
 import { mapGetters } from 'vuex';


export default {
  name: 'PaymentInfo',
  props:{
  },
  data() {
    return {
      paymentPp: '',
      isSending: false
    }
  },
  methods:{
    ...mapGetters(['getProfileId', 'getProfile']),
    paymentPpText(){
      return this.getProfile()?.profile?.paymentPp ? this.getProfile().profile.paymentPp : 'not set';
    },
    commissionValue(){
      return this.getProfile()?.profile?.commissionPercentage;
    },
    submitMessage(){
      this.isSending = true;
      const prof = this.getProfileId();
        PostWithAuth("https://api.cartogro.com/api/messaging/send/" + prof.replace('auth0|',''), {subject: '***PP UPDATE REQUEST', content: this.paymentPp})

        .then(json => {
          //if(json.isSuccessful){
            this.paymentPp = '';
            this.$buefy.toast.open({
                    message: 'Request submitted',
                    type: 'is-success'
                })
          //}
          this.isSending = false;
        })
        .catch(err => {
          console.log(err);
          this.$buefy.toast.open({
                    message: 'Sorry, something went wrong and we were unable to submit your request',
                    type: 'is-danger'
                })
          this.isSendinng = false;
        })
    },
  },  
  components: {  
  },
  mounted(){
    const profId = this.getProfileId();
    if(profId){
      TrackEvent(profId.replace('auth0|',''),"paymentInfoPageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        TrackEvent(profile.id.replace('auth0|',''),"paymentInfoPageLoad-onEventSet");
      })
    }
  },
};
</script>

<style scoped>
</style>