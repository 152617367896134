<template>
<l-marker  ref="marker" :lat-lng="latLng" @click="pinClick()" :draggable="draggable">
  
         <l-icon
          :icon-anchor="[8,35]"
          class-name="someExtraClass"
        >
        
        <!--<div v-if="showTitle" class="pin-title">{{title}}</div>-->
          <div class="headline inner-pin-con">
            <div class="inner-pin-title"  :style="{'color':color, 'width': labelWidth + 'px', 'left': labelOffset + 'px'}">{{title}}</div>
            <div class="inner-pin" :style="{ 'top' : `${this.iconOffset()}px`}">
              <b-icon
                :icon="icon"
                :style="{'color':color }"
                size="is-small">
            </b-icon>
              <!--{{icon}}
              <span class="icon custom-icon">
                <i class="fa" :style="{'color':color}" v-bind:class='getIconClass(icon)'></i>
              </span>-->
            </div>
                <div class='pin' :style="{'background':color}"  v-bind:class='{pinAdding : false}' >
                  
                </div>
                 <div v-if="showPulse" class='pulse'></div>
              </div>
          </l-icon>
          <div v-if="!!description">
        <l-popup :options="{offset:[-1,-20]}">
          <div class="popup-content">
            <div><strong>{{title}}</strong></div>
            <div>{{description}}</div>
            <!--<button class="button" @click="pinEdit()">Edit</button>-->
          </div>
        </l-popup></div>
      </l-marker>      
</template>

<script>
import { LMarker, LPopup, LIcon } from "vue2-leaflet";

export default {
  name: "MapMarker",
  components: {
    LMarker,
    LPopup,
    LIcon,
  },
  props: {
    //pin: Object
    title: String,
    description: String,
    color: String,
    icon: String,
    showTitle: Boolean,
    showPulse: Boolean,
    animate: Boolean,
    draggable: Boolean,
    latLng: Object
  },
  emit:['pinClick', 'pinEdit'],
  data() {
    return {
      icons: [
        {id: 'circle', name: 'Circle', offset: -1},
              {id: 'star', name: 'Star', offset: -2},
              {id: 'heart', name: 'Heart', offset: 0},
              {id: 'sun', name: 'Sun', offset: -1},
              {id: 'moon', name: 'Moon', offset: -1},
              {id: 'snowflake', name: 'Snow'},
              {id: 'binoculars', name: 'Binoculars', offset: -1},
              {id: 'camera', name: 'Camera', offset: -1},
              {id: 'cocktail', name: 'Cocktail', offset: -1},
              {id: 'beer', name: 'Beer', offset: 1},
              {id: 'wine-glass-alt', name: 'Wine', offset: 1},
              {id: 'wine-bottle', name: 'Wine Bottle'},
              {id: 'glass-cheers', name: 'Cheers', offset: -2},
              {id: 'coffee', name: 'Coffee', offset: -1},
              {id: 'place-of-worship', name: 'Place of Worship', offset: -3},
              {id: 'monument', name: 'Monument'},
              {id: 'vihara', name: 'Vihara', offset: -3},
              {id: 'home', name: 'Home', offset: -2},
              {id: 'hotel', name: 'Hotel', offset: -1},
              {id: 'mosque', name: 'Mosque', offset: -2},
              {id: 'landmark', name: 'Landmark', offset: -1},
              {id: 'archway', name: 'Arch', offset: -2},
              {id: 'hospital', name: 'Hospital'},
              {id: 'ambulance', name: 'Ambulance', offset: -2},
              {id: 'clinic-medical', name: 'Clinic', offset: -3},
              {id: 'utensils', name: 'Utensils', offset: 1},
              {id: 'hamburger', name: 'Burger', offset: -1},
              {id: 'ice-cream', name: 'Icecream', offset: 1},
              {id: 'pizza-slice', name: 'Pizza', offset: -2},
              {id: 'campground', name: 'Tent', offset: -3},
              {id: 'caravan', name: 'Caravan', offset: -1},
              {id: 'mountain', name: 'Mountain', offset: -2},
              {id: 'swimmer', name: 'Swimmer', offset: -2},
              {id: 'water', name: 'Water', offset: -2},
              {id: 'anchor', name: 'Anchor', offset: -2},
              {id: 'skull-crossbones', name: 'Skull and Crossbones'},
              {id: 'tree', name: 'Tree', offset: 1},
              {id: 'umbrella-beach', name: 'Beach', offset: -3},
              {id: 'info', name: 'Info', offset: 1},
              {id: 'torii-gate', name: 'Gate'},
              {id: 'skiing', name: 'Skiing'},
              {id: 'shopping-bag', name: 'Shopping'},
              {id: 'shopping-basket', name: 'Basket', offset: -2},
              {id: 'shopping-cart', name: 'Supermarket', offset: -1},
              {id: 'store', name: 'Store', offset: -1},
              {id: 'gifts', name: 'Gifts', offset: -2},
              {id: 'money-bill', name: 'Money', offset: -2},
              {id: 'theater-masks', name: 'Theatre', offset: -1},
              {id: 'palette', name: 'Palette', offset: -1},
              {id: 'guitar', name: 'Guitar'},
              {id: 'compact-disc', name: 'Record'},
              {id: 'music', name: 'Music'},
              {id: 'film', name: 'Film'},
              {id: 'bed', name: 'Bed', offset: -2},
              {id: 'flag', name: 'Flag', offset: -1},
              {id: 'flag-checkered', name: 'Checkered Flag', offset: -1},
              {id: 'walking', name: 'Walking' },
              {id: 'hiking', name: 'Hiking'},
              {id: 'running', name: 'Running'},
              {id: 'motorcycle', name: 'Motorbike', offset: -2},
              {id: 'biking', name: 'Bike', offset: -2},
              {id: 'parking', name: 'Parking'},
              {id: 'bus', name: 'Bus', offset: -1},
              {id: 'car', name: 'Car', offset: -1},
              {id: 'ship', name: 'Ship', offset: -3},
              {id: 'taxi', name: 'taxi', offset: -1},
              {id: 'subway', name: 'Subway'},
              {id: 'shuttle-van', name: 'Shuttle Van', offset: -2},
              {id: 'tram', name: 'Tram', offset: -1},
              {id: 'passport', name: 'Passport'},
              {id: 'fish', name: 'Fish', offset: -2},
              {id: 'dog', name: 'Dog', offset: -2},
              {id: 'horse', name: 'Horse', offset: -1},
              {id: 'crow', name: 'Bird', offset: -2},
              {id: 'wheelchair', name: 'Wheelchair', offset: -1},
              {id: 'wifi', name: 'Wifi', offset: -2},
              {id: 'restroom', name: 'Restroom', offset: -2},
              {id: 'spa', name: 'Spa', offset: -1},
              {id: 'dice', name: 'Dice', offset: -2},
              {id: 'building', name: 'Building'},
              {id: 'city', name: 'City', offset: -2},
              {id: 'virus', name: 'Virus', offset: -1},
              {id: 'virus-slash', name: 'no Virus', offset: -2},
              {id: 'head-side-mask', name: 'Facemask', offset: -1},

              {id: 'train', name: 'Train'},
              {id: 'plane', name: 'Plane', offset: -2},
              {id: 'plane-departure', name: 'Plane Departure', offset: -2},
              {id: 'plane-arrival', name: 'Plane Arrival', offset: -2},
              {id: 'helicopter', name: 'Helicopter', offset: -2},
              {id: 'phone', name: 'Phone', offset: -1},
              {id: 'road', name: 'Road', offset: -3},
              {id: 'route', name: 'Route', offset: -2},
              {id: 'shoe-prints', name: 'Shoe Prints', offset: -2},
              {id: 'ticket-alt', name: 'Ticket', offset: -2},
              {id: 'wrench', name: 'Wrench', offset: -1},
              {id: 'university', name: 'University', offset: -2},
              {id: 'dumbbell', name: 'Dumbbell', offset: -2},
              {id: 'swimming-pool', name: 'Swimming Pool', offset: -2},
              {id: 'book', name: 'Book'},
              {id: 'graduation-cap', name: 'Graduation', offset: -3},              
              {id: 'futbol', name: 'Soccer', offset: -1},
              {id: 'golf-ball', name: 'Golf'},
              {id: 'skating', name: 'Skating'},
      ],
     // routeTypes: [
     //   {
     //     key: 'driving-car',
     //     text: 'Car'
     //   }
     // ]
    };
  },
  computed: {   
    labelWidth: function(){
      return this.title.length * 6;
    },
    labelOffset: function(){
      const val = this.title.length * 6;
      return (0 - (val/2)) + 6;
    }
      //pinLatLng: function(){
      //    return latLng(pin.lat, pin.lng);
      //} 
  },
  methods: {
    getIconClass(iconId){
      return `fa-${iconId}`;
    },
    pinClick(pin){
      this.$emit('pinClick', pin);
    },
    editClick(pin){
      this.$emit('pinEdit', pin);
    },
    iconOffset(){
      const icon = this.icons.find(x=>x.id == this.icon);
      return icon?.offset ?? 0;
    }
  }
}
</script>


<style scoped>

.inner-pin-title
{
/*text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.9), 1px 1px 1px rgba(255, 255, 255,.8);*/
text-shadow:
1px 1px #fff,
-1px 1px #fff,
1px -1px #fff,
-1px -1px #fff,
1px 1px 5px #555;
  font-weight: bold;
  text-align: center;
  position: absolute;
  
  top: -21px;
  /*width: 480px;
  left: -234px;*/
  font-size: 12px;  
  z-index: 9;

  display: inline-block;
  white-space: nowrap;
}

.inner-pin
{
  position: absolute;
 /* top: 0px; /* overriden*/
  left:-1px;
  font-size: 20px;  
  z-index: 9;
}

.popup-content{
  width:150px;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #333333;
/*  position: absolute;*/
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -8px;
  
  z-index: 998;  
}

.someExtraClass:hover .pin{
  box-shadow: 0px 0px 15px 4px rgba(255, 255, 255,0.35);
-webkit-box-shadow: 0px 0px 15px 4px rgba(255, 255, 255,0.35);
-moz-box-shadow: 0px 0px 15px 4px rgba(255,255,255,0.35);
}

.pinAdding{
    animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  }
  .custom-icon{
    font-size: 15px;
    margin-left: -7px;
  }
 .pin:after {
    content: '';
    width: 26px;
    height: 26px;
    margin: 2px 0 0 2px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 50%;
 }  
.pulse {
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 21px 0px 0px -6px;
  transform: rotateX(55deg);
  z-index: -2;
}
 .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    box-shadow: 0 0 1px 2px #89849b;
    animation-delay: 1.1s;
 }
@keyframes pulsate{
  0%{
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50%{
    opacity: 1.0;
  }
  100%{
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
  

@keyframes bounce
{
  0%{
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60%{
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80%{
    transform: translateY(-10px) rotate(-45deg);
  }
  100%{
    transform: translateY(0) rotate(-45deg);
  }
}
</style>