<template>
  <div>
        <b-field label="Subject">
            <b-input v-model="subject"></b-input>
        </b-field>
        <b-field label="Message">
            <b-input maxlength="500" type="textarea" v-model="content"></b-input>
        </b-field>
        <div class="buttons">
            <b-button icon-left="edit" type="is-primary" @click="submitMessage" :class="{'is-loading' : isSending}">Send Message</b-button>
            <!--<b-button icon-left="times" @click="cancelMessage">Cancel</b-button>-->
        </div>
    </div>
</template>


<script>
 //import { PostWithAuth, GetWithAuth } from '../services/apiClient';
  import { PostWithAuth } from '../services/apiClient';
 import { mapGetters } from 'vuex';

export default {
  name: 'Contact',
  props:{
    //mapId: String
  },
  data() {
    return {
      subject: '',
      content: '',
      isSending: false
    }
  },
  methods:{
    ...mapGetters(['getProfileId']),
    submitMessage(){
      this.isSending = true;
      const prof = this.getProfileId();
        PostWithAuth("https://api.cartogro.com/api/messaging/send/" + prof.replace('auth0|',''), {subject: this.subject, content: this.content})

        .then(json => {
          //if(json.isSuccessful){
            console.log(json);
            this.subject = '';
            this.content = '';
            this.$buefy.toast.open({
                    message: 'Message sent!',
                    type: 'is-success'
                })
          //}
          this.isSending = false;
        })
        .catch(err => {
          console.log(err);
          this.$buefy.toast.open({
                    message: 'Sorry, something went wrong and we were unable to send your message',
                    type: 'is-danger'
                })
          this.isSendinng = false;
        })
    },
    cancelMessage(){
        this.subject = '';
            this.content = '';
            this.$buefy.toast.open({
                    message: 'Message cancelled'
                })
    }
  },  
  mounted() {
    
  },
  created() {
   
  },
  components: {    
  },
};
</script>

<style scoped>
</style>