<template>
  <div>
        <b-field label="search for location">
            <b-autocomplete
            expanded
                v-model="locationSearch"
                :data="dataList"
                placeholder="e.g. Amsterdam"
                icon="search"
                clearable
                @select="option => locationChosen(option)">
                <template #empty>No results found</template>
            </b-autocomplete>
            <p class="control">
                <b-button type="is-primary" label="Find" @click="geoSelected()" :disabled="!selected"/>
            </p>
        </b-field>
    </div>
</template>


<script>

 import axios from "axios";
 import { mapGetters } from 'vuex';

export default {
  name: 'GeoSearch',
  props:{
    //mapId: String
  },
  data() {
    return {
      locationSearch: '',
      selected: null,
      selectedName: '',
      waitCount: 0,
      currentData: []
    }
  },
  methods:{
    ...mapGetters(['getProfileId']),
    geoSelected(){
      this.$emit('locationSelected', this.selected);

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"geoSearch-geoSelected");
      }
    },
    locationChosen(option){
      const data= this.currentData.find( x=> x.properties.label == option);
      if(data){
        this.selected = data.geometry.coordinates;
        this.selectedName = data.properties.label;

        const profId = this.getProfileId();
        if(profId){
          TrackEvent(profId.replace('auth0|',''),"geoSearch-locationChosen");
        }
      }
      
    }
  },  
  watch: {
    locationSearch: function (newValue, oldValue) {
              if(newValue != this.selectedName){
                this.selected = null;
              }
              if(this.waitCount == 0 && !!newValue){
                
                this.waitCount++;
                setTimeout(()=> {                  
                  this.waitCount--;
                }, 150);
                
                  const url = `https://api.openrouteservice.org/geocode/autocomplete?api_key=5b3ce3597851110001cf624811a4640ee65d489d9867d81b82cd32d4&text=${newValue}`
                axios.get(url).then(data => {
                  //this.journey.push({geoJson: data, lat: pin.lat, lng: pin.lng});
                  if(!data.error){
                    //console.log(data);
                    //console.log(data.features.map(x=> x.properties.label));
                    if(data.data.features){
                      this.currentData = data.data.features;
                    }
                  }
                  else{
                    this.$buefy.toast.open({
                      message: 'Sorry there was a problem searching for locations. Try again shortly?',
                      type: 'is-warning'
                  })
                  }
                  
                });
              
              
              }
    }
  },
  computed:{
    dataList: function(){
      return this.currentData.map(x=> x.properties.label);
    }
  },
  computedAsync: {
        },
  emit:['locationSelected'],
  mounted() {
    
  },
  created() {
   
  },
  components: {    
  },
};
</script>

<style scoped>
</style>