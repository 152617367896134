<template>
  <div>
    <div class="card side-panel">
      <header class="card-header">
        <p class="card-header-title">
          {{title}}
        </p>
      </header>
      <div class="card-content">
        <div class="content">
           {{content}}
        </div>
      </div><!--
    <footer class="card-footer">
        <a @click="cancel()" class="card-footer-item">Cancel</a>
      </footer>  -->
      <button class="modal-close is-large is-danger side-panel-close-btn" @click="cancel()" aria-label="close"></button>
  </div>

</div>  
</template>

<script>

export default {
  name: "MapEditorPanelHelp",
  components: {
  },
  props: {
    title: String,
    content: String
  },
  data() {
    return {
      

    };
  },
  computed: {    
  },
  methods: {
    cancel(){
      this.$emit('cancel');
    },
   
  },
  emit:['cancel']
}
  

</script>


<style scoped>


.side-panel-close-btn{
  position: absolute;
  top: 10px;
  right: 10px;
}

.side-panel-close-btn::before, .side-panel-close-btn::after {
  background-color: #333333;
}

.side-panel {
  position: absolute;
  width: 30%;
  min-width:280px;
  right: 10px;
  top: 10px;
  max-height: 90%;
  z-index:999;
}

</style>