<template>
<div>
  <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li v-if="actionVerb == 'Edit'"><router-link :to="'/maps/' + getMapConfig().id">{{getMapConfig().name}}</router-link></li>
                        <li v-if="actionVerb == 'Edit'" class="is-active"><router-link to="/">Edit Map</router-link></li>
                        <li v-if="actionVerb == 'Create'" class="is-active"><router-link to="/">Create Map</router-link></li>
                    </ul>
                </nav>
                <section class="hero welcome is-small is-info is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                {{actionVerb}} Map
                            </h1>
                            <h2 class="subtitle" v-if="actionVerb == 'Create'">
                                Create a new map widget to embed on your website
                            </h2>
                            <h2 class="subtitle" v-if="actionVerb == 'Edit'">
                                Making changes to map widget <b>{{getMapConfig().name}}</b>
                            </h2>
                        </div>
                    </div>
                </section>   
                <div class="content"/>
                <div v-if="$isMobile()" class="content">

                    <div class="card message-container">
                <b-message 
                    title="Device not yet supported" 
                    type="is-info" 
                    has-icon
                    aria-close-label="Hide">
                    <p>We're really sorry, but the map maker doesn't yet work properly on mobile devices.  We're working on an improved version which we hope to share shortly!</p>
                     <p>In the meantime, our existing map maker is available on desktop and tablet devices.</p>
                </b-message>
                </div>

                  </div>
                <div class="content" style="position: relative;" v-if="!$isMobile()">
                  <!--<Tooltip text="Give your map a friendly name to identify it - this won't appear on your webpages."/>-->
                  <b-field label="widget name">
                      <b-input placeholder="My new map"
                          v-model="widgetName"
                          icon="edit"
                          maxlength="30">
                      </b-input>                      
                  </b-field>
              </div>

                <div class="panel"  v-if="!$isMobile()"><!--
                      <p class="panel-heading">
                        Map editor
                      </p>-->
            
                    <div class="columns panel-block content"><div class="column" style="position: relative;">
                      <!--<Tooltip style="top:15px; right: 15px;" text="Quickly find and zoom to a location using the search bar, before manually adjusting the position/zoom to you preferred position."/>-->
                      <GeoSearch @locationSelected="setMapLocation"/>
                      </div>
                      <div class="column" >
                        <div>Latitude: <b>{{getMapConfig().lat}}</b></div>
                        <div>Longitude: <b>{{getMapConfig().lng}}</b></div>
                        <div>Zoom: <b>{{getMapConfig().zoom}}</b></div>
                      </div>
                    </div>
                    

                      <div class="panel-block content" >
                        <!--<Tooltip style="top:15px; right: 15px;" text="Quickly find and zoom to a location using the search bar, before manually adjusting the position/zoom to you preferred position."/>
                        -->
                        <b-field label="select map style">
                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Standard"
                                type="is-primary is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Standard</span>
                            </b-radio-button> 

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Street"
                                type="is-warning is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Street</span>
                            </b-radio-button> 


                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Satellite"
                                type="is-info is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Satellite</span>
                            </b-radio-button>

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Hybrid"
                                type="is-success is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Hybrid</span>
                            </b-radio-button>
<!--
                            <b-radio-button v-model="radioButton"
                                native-value="Satellite2"
                                type="is-success is-light is-outlined">
                                <span>Satellite 2</span>
                            </b-radio-button>

                            <b-radio-button v-model="radioButton"
                                native-value="SatelliteLabelled"
                                type="is-success is-light is-outlined">
                                <span>Satellite Labelled</span>
                            </b-radio-button>-->

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Outdoor"
                                type="is-warning is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Outdoor</span>
                            </b-radio-button> 

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Topographic"
                                type="is-primary is-light is-outlined">
                                <!--<b-icon icon="check"></b-icon>-->
                                <span>Topographic</span>
                            </b-radio-button> 

                            <!--<b-radio-button v-model="selectedMapStyle"
                                native-value="Winter"
                                type="is-info is-light is-outlined">
                                <span>Winter</span>
                            </b-radio-button> -->

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Bright"
                                type="is-warning is-light is-outlined">
                                <span>Bright</span>
                            </b-radio-button> 

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Pastel"
                                type="is-success is-light is-outlined">
                                <span>Pastel</span>
                            </b-radio-button> 

                            <b-radio-button v-model="selectedMapStyle"
                                native-value="Osm"
                                type="is-danger is-light is-outlined">
                                <span>OSM</span>
                            </b-radio-button>


                        </b-field>
                        
                      </div>

                      <div class="panel-block">
                        <div class="buttons content">
                            <b-button
                                icon-left="map-marker-alt"
                                :selected="currentMode == 'addPin'"
                                @click="currentMode = 'addPin'"
                                >
                                Add New Marker
                            </b-button>

                            <b-button
                                icon-left="route"
                                :selected="currentMode == 'route'"
                                @click="currentMode = 'route'"
                                >
                                Create/Edit Route
                            </b-button>

                            <b-button
                                icon-left="vector-square"
                                :selected="currentMode == 'area'"
                                @click="currentMode = 'area'"
                                >
                                Create/Edit Area
                            </b-button>

                            <b-field v-show="!$isMobile()" label="map height (pixels)" label-position="on-border" size="is-small" style="position: absolute; right: 20px; width: 200px;" >
                          <b-numberinput 
                          size="is-small"
                              v-model="widgetHeight"
                              :min="100"
                              icon="edit">
                          </b-numberinput >
                      </b-field>
                            
                          </div>
                          
                      </div>
                      
                      <div class="panel-block">
                          <MapEditorMap 
                            v-if="mapActive"
                            @updateBounds="boundsUpdate" 
                            :componentKey="componentKey"
                            :editMode="currentMode"
                            :mapSearchLocation="mapSearchLocation" />

                      </div>
                    </div>

                    <div class="columns"  v-if="!$isMobile()">      
                        <div class="column">
                            <b-button size="is-medium"
                                outlined
                                expanded
                                icon-left="times"
                                type="is-danger"
                                @click="cancel()"
                                >
                                Cancel
                            </b-button>

                        </div>
                        <div class="column">

                            <b-button 
                                expanded
                                size="is-medium"
                                icon-left="check"
                                type="is-primary"
                                v-if="actionVerb == 'Create'"
                                 @click="save()"
                                 :class="{'is-loading' : isSaving}"
                                >
                                Create
                            </b-button>

                            <b-button 
                                expanded
                                size="is-medium"
                                icon-left="check"
                                type="is-primary"
                                v-if="actionVerb == 'Edit'"
                                 @click="edit()"
                                 :class="{'is-loading' : isSaving}"
                                >
                                Save
                            </b-button>
                            
                          </div>
                        </div>
 
            </div>
</template>

<script>
// @ is an alias to /src

 import MapEditorMap from '../components/mapEditor/MapEditorMap';
 import GeoSearch from '../components/GeoSearch';
 import Tooltip from '../components/Tooltip';
 import 'leaflet/dist/leaflet.css';
 import { PostWithAuth, TrackEvent } from '../services/apiClient';
 import { mapGetters, mapActions, mapMutations } from 'vuex';



export default {
  name: 'Dashboard',
  props:{
    mapId: String
  },
  data() {
    return {
      componentKey: false,
      actionVerb: "Create",
      mapBounds: null,
      currentMode: 'edit',
      widgetName: '',
      widgetHeight: 400,
      isSaving: false,
      selectedMapStyle: 'Standard',
      mapSearchLocation: null,
      mapActive: true
    }
  },
  beforeRouteEnter (to, from, next) {
    //this.mapConfig = null
    //this.setData(to.params.mapId, (mapId) => {
      //alert("enter " + to.params.mapId);
      next(vm => vm.setData(to.params.mapId))
    //})
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    //this.mapConfig = null
    //this.setData(to.params.mapId, (mapId) => {
      //alert("upadte " + to.params.mapId);
      this.setData(to.params.mapId)
      next()
    //})
  },
  watch: {
    widgetHeight: function (newValue, oldValue) {
     this.updateWidgetHeight(newValue);
     const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"editor-updateWidgetHeight");
      }
    },
    widgetName: function(newValue, oldValue){
      this.updateWidgetName(newValue);
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"editor-updateWidgetName");
      }
    },
    selectedMapStyle: function(newValue, oldValue){
      
      this.updateMapStyle(newValue);
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"editor-updateMapStyle");
      }
    }
  },
  //watch: {
  //  $route: function (to){
  //          
  //    if(to.params.mapId){
  //      alert('route watched edit');
  //      this.actionVerb = "Edit";
  //      GetWithAuth("https://localhost:44369/api/mapconfigs/" + to.params.mapId,'')
  //      .then(response => response.json())
  //      .then(json => {
  //        this.mapConfig = json;
  //        //if(json.isSuccessful){
  //          console.log(json);
  //        //}
  //      })
   //     .catch(err => {
   //       console.log(err);
   //     })        
   //   }
   //   else{
    //    alert('route watched create');
    //    this.actionVerb = "Create";
    //    this.mapConfig = { 
    //    widgetHeight: 600,
    //    filters:{
    //      categoryGroupIds: []
    //    },
    //    lat: 15,
    //    lng: 12,
    //    zoom: 2,
     //   name: "",
      //    pois: {},
     //     pins: [],
     //     route: [],
     //     routeColor: '#1122AA',
      //    routeLineWeight: 5,
      //    area: {
      //      outlineColor: '#1122AA',
      //      outlineWeight: 5,
      //      fillColor: '#1122AA',
      //      fillOpacity: 0.5
      //    }
      //  }      
      //}  
      //this.componentKey = !this.componentKey;    
    //},
  //},
  methods:{
    ...mapGetters(['getAccessToken','getMapConfigs', 'getMapConfig', 'getProfileId']),
    ...mapActions(['loadMapConfig']),
    ...mapMutations(['resetMapConfig','updateWidgetName','updateWidgetHeight', 'updateMapStyle']),
    setData(mapId){
      if(mapId){
        this.actionVerb = "Edit";
        this.loadMapConfig(mapId) // gets from api, bad name
        .then(json => { //finished loading
        //alert(json.name)
           this.widgetName = json.name;
           this.widgetHeight = json.widgetHeight;
           this.selectedMapStyle = json.mapStyle ?? 'Standard';
           this.componentKey = !this.componentKey;  
        })
        .catch(err => {
          console.log(err);
        })       
      }
      else{
        this.actionVerb = "Create";
        this.resetMapConfig();
        this.componentKey = !this.componentKey;  
        this.widgetHeight = 400;
      }  
        
    },
    boundsUpdate(bounds){
      if(this.mapBounds == null || 
      (bounds._southWest.lat != this.mapBounds._southWest.lat
      && bounds._southWest.lng != this.mapBounds._southWest.lng
      && bounds._northEast.lat != this.mapBounds._northEast.lat
      && bounds._northEast.lng != this.mapBounds._northEast.lng))
      {
        this.mapBounds = bounds;
      }
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"editor-updateBounds");
      }
    },
    //updateAreaStyle(areaStyle){
    //  alert(areaStyle);
    //  this.mapConfig.area.outlineColor = areaStyle.outlineColor;
    //  this.mapConfig.area.outlineWeight = areaStyle.outlineWeight;
    //  this.mapConfig.area.fillColor = areaStyle.fillColor;
    //  this.mapConfig.area.fillOpacity = areaStyle.fillOpacity;
    //},
    cancel(){
      this.mapActive = false;
      this.resetMapConfig();
      if (this.actionVerb == "Edit"){        
        this.$router.push({ name: 'MapSummary', params: {mapId: this.mapId }});
      }
      else{
        this.$router.push({ name: 'Home' });
      }

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"editor-cancelled");
      }
      
    },
    save(){
      this.isSaving = true;
      const prof = this.getProfileId();
      const mapConfig = this.getMapConfig();
      if(!mapConfig.name){
        this.updateWidgetName("My new map");
      }
      //PostWithAuth("https://localhost:44369/api/mapconfigs/create/" + prof.replace('auth0|',''), mapConfig)
      PostWithAuth("https://api.cartogro.com/api/mapconfigs/create/" + prof.replace('auth0|',''), mapConfig)

        .then(json => {
          //if(json.isSuccessful){
            console.log(json);
            this.$emitter.emit('mapUpdated', this.account);
            this.isSaving = false;
            this.$buefy.toast.open({
                    message: `Saved map ${this.getMapConfig().name}`,
                    type: 'is-success'
                })
            this.$router.push({ name: 'MapSummary', params: {mapId: json.id }});
          //}
        })
        .catch(err => {
          console.log(err);
        })

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"savingNewMap");
      }
    },
    edit(){
      this.isSaving = true;
      const prof = this.getProfileId();

     // PostWithAuth("https://localhost:44369/api/mapconfigs/update/" + prof.replace('auth0|',''), this.getMapConfig())
      PostWithAuth("https://api.cartogro.com/api/mapconfigs/update/" + prof.replace('auth0|',''), this.getMapConfig())

        .then(response => {
          //if(json.isSuccessful){
            this.$emitter.emit('mapUpdated', this.account);
           this.isSaving = false;
            this.$buefy.toast.open({
                    message: `Saved map ${this.getMapConfig().name}`,
                    type: 'is-success'
                })
                
            this.$router.push({ name: 'MapSummary', params: {mapId: response.id }});
          //}
        })
        .catch(err => {
          console.log(err);
        })

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"savingEditedMap");
      }
    },
    setMapLocation(coords){
      console.log(coords);
      const data = {
        lat: coords[1],
        lng: coords[0],
        coords: coords,
        zoom: 11
      }
      this.mapSearchLocation = data;
    }
  },  
  mounted() {
    this.mapActive = true;
    const prof = this.getProfileId();
    if(this.mapId){
      this.loadMapConfig(this.mapId)
        .then(json => {
           this.widgetName = json.name;
           this.widgetHeight = json.widgetHeight;
           this.selectedMapStyle = json.mapStyle ?? 'Standard';
        })
        .catch(err => {
          console.log(err);
        })
        if(this.$isMobile()){
          TrackEvent(prof.replace('auth0|',''),"MOBILE-editMapMounted-edit");
        }
        else{
          TrackEvent(prof.replace('auth0|',''),"editMapMounted-edit");
        }     
    
    }    
    else{
      if(this.$isMobile()){
          TrackEvent(prof.replace('auth0|',''),"MOBILE-editMapMounted-new");
        }
        else{
          TrackEvent(prof.replace('auth0|',''),"editMapMounted-new");
        }    
    
    }
    
    
  },
  created() {
    this.$emitter.on(
      'login', (account) => {
        console.log(account);
      }),
    this.$emitter.on('logout', () => {
        console.log('logging out');
      });
    this.$emitter.on('resetToEditMode', () => {
        this.currentMode = 'edit';
      });
    this.$emitter.on('resetToAddPinMode', () => {
        this.currentMode = 'addPin';
      });
  },
  components: {
    MapEditorMap,
    GeoSearch,
    Tooltip
  },
};
</script>

<style scoped>
  .height-input{
    max-width:140px;
  }

  .height-input-label{
    margin-right:0px;
    line-height: 1.3;
    max-width:100px;
  }

  .height-field-body{
    max-width:160px;
  }
</style>