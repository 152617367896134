<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/profile">Profile</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <div>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Profile
                            </h1>
                            <h2 class="subtitle">
                                Your profile details
                            </h2>
                        </div>
                    </div>
                </section>
                <div class="block"></div>
                <div class="block">
                    Need to change your details?  Contact us via the form below with and we'll email you at {{$auth.user.name}} to confirm the changes.
                  </div>
                <!--<div class="block">
                  <table class="table">
                      <tbody>
                        <tr>
                          <td>Nickname</td>
                          <td>{{$auth.user.nickname}}</td>
                        </tr>
                        <tr>
                          <td>Email Adress</td>
                          <td>{{$auth.user.name}}</td>
                        </tr>
                        <tr>
                          <td>Affiliate ID</td>
                          <td>{{$auth.user.name}}</td>
                        </tr>
                        <tr>
                          <td>Profile ID</td>
                          <td>{{$auth.user.name}}</td>
                        </tr>
                        <tr>
                          <td>Profile Status</td>
                          <td>{{$auth.user.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <Contact/>
                -->

                <div class="panel">
  <p class="panel-heading">
    Your Details
  </p>
  <div class="panel-block">
    <span class="panel-icon">
      <i class="fas fa-book" aria-hidden="true"></i>
    </span>
    <span>Nickname: {{$auth.user.nickname}}</span>
  </div>
  <div class="panel-block">
    <span class="panel-icon">
      <i class="fas fa-book" aria-hidden="true"></i>
    </span>
    <span>Nickname: {{$auth.user.nickname}}</span>
  </div>
  <div class="panel-block">
    <span class="panel-icon">
      <i class="fas fa-book" aria-hidden="true"></i>
    </span>
    <span>Nickname: {{$auth.user.nickname}}</span>
  </div>
  <div class="panel-block">
    <span class="panel-icon">
      <i class="fas fa-book" aria-hidden="true"></i>
    </span>
    <span>Nickname: {{$auth.user.nickname}}</span>
  </div>
</div>


                <div class="container box">
                    <h3 class="subtitle">Update your details</h3>
                   <Contact/>
                </div>

</div>
  </div>
</template>


<script>
 import { TrackEvent } from '../services/apiClient';
 import Contact from '../components/Contact.vue';
  import { mapGetters } from 'vuex';

export default {
  name: 'Profile',
  props:{
  },
  data() {
    return {
    }
  },
  methods:{
    ...mapGetters(['getProfileId']),
  },  
  components: {    
      Contact
  },
  mounted(){
    const profId = this.getProfileId();
    if(profId){
      TrackEvent(profId.replace('auth0|',''),"profilePageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        TrackEvent(profile.id.replace('auth0|',''),"profilePageLoad-onEventSet");
      })
    }
  },
};
</script>

<style scoped>
</style>