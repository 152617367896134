<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/contact">Payout Slips</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <div>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Payouts
                            </h1>
                            <h2 class="subtitle">
                                Pending payouts and history
                            </h2>
                        </div>
                    </div>
                </section>
                <div class="block"></div>
                <div class="block">
                    Pending payout slips are typically proccessed within a few days of being generated, and includes all unpaid confirmed commissions.
                  </div>
                  
                <div class="block">
                  <b-table :data="payoutSlips" :columns="columns"></b-table>
                  </div>
                </div>

  </div>
</template>


<script>
 import { GetWithAuth, TrackEvent } from '../services/apiClient';
   import { mapGetters} from 'vuex';
    import date from 'date-and-time';
// import Contact from '../components/Contact.vue'

export default {
  name: 'PayoutSlips',
  props:{
  },
  data() {
    return {
      payoutSlips: [],
      columns: [
        {
            field: 'id',
            label: 'Payout ID',
           // centered: true
        },
        {
            field: 'totalBookings',
            label: 'Bookings'
        },
        {
            field: 'generatedDateTime',
            label: 'Generated'
        },
         {
            field: 'payoutSlipStatus',
            label: 'Payout Status'
        },
        {
            field: 'generatedDateTime',
            label: 'Paid'
        },         
        {
            field: 'totalValue',
            label: 'Total Commssion'
        } 
      ]
    }
  },
  mounted(){
    const profId = this.getProfileId();
    if(profId){
      this.getData(profId);
      TrackEvent(profId.replace('auth0|',''),"payoutSlipsPageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        this.getData(profile.id);
        TrackEvent(profile.id.replace('auth0|',''),"payoutSlipsPageLoad");
      })
    }
  },
  methods:{
    getData(prof){
      GetWithAuth(`https://api.cartogro.com/api/stats/${prof.replace('auth0|','')}/payouts`)
        .then(response => {
          response.payoutSlips.forEach(x=>{            
            x.generatedDateTime = this.formatDate(x.generatedDateTime); 
            x.PayoutDateTime = this.formatDate(x.payoutDateTime); 
            x.payoutSlipStatus = this.formatPayoutStatus(x.payoutSlipStatus);
            x.totalBookings = x.bookings.length;
            x.totalValue = "$" + x.totalValue.toFixed(2);
          })
          this.payoutSlips = response.payoutSlips;
        })
        .catch(() => {
        })
    },
    ...mapGetters(['getProfileId']),
    formatDate(dat){
      return date.format(new Date(dat), 'ddd, MMM DD YYYY');
    },
    formatPayoutStatus(status){
      if(status === 0){
        return 'Processing';
      }
      else if(status === 1){
        return 'Paid';
      }
      else return '-'
    },
  },  
  components: {    
  },
};
</script>

<style scoped>
</style>