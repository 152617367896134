<template>
<div>
  <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
            <div class="card">
               <div class="card-content">       
                    <div class="content">
                        Are you sure you want to delete map <b>{{getMapConfig().name}}</b>?
                        <br>
                        <small>Once deleted you will not be able to recover this map.</small>
                    </div>
                    <div class="content">
                        
                        <div class="buttons">
                              <b-button icon-left="trash" type="is-danger" @click="deleteMap"  :class="{'is-loading' : isDeleting}">Delete map</b-button>
                              <b-button icon-left="times" @click="isCardModalActive = false">Cancel</b-button>
                          </div>
                    </div>
                </div>
            </div>
        </b-modal>


<nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/">{{getMapConfig().name}}</router-link></li>
                    </ul>
                </nav>

<b-loading v-model="isPageLoading" ></b-loading>

   <section class="hero is-info welcome is-small is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                {{getMapConfig().name}}
                            </h1>
                            <h2 class="subtitle">
                                Map Widget
                            </h2>
                        </div>
                    </div>
                </section>   

<div class="content"/>
<section>
                  <div class="container columns">
                    <div class="column is-two-thirds">
                    Insert the following HTML snippet onto your page where the map should be positioned.  For more information see our <a>integration guide</a>.  Alternatively, just copy and share the link below.
                    </div>
                      <div class="column">
                      <div class="buttons is-right">
                        <b-button type="is-primary" icon-left="edit" tag="router-link" :to="getMapConfig().id + '/edit'">Edit Widget</b-button>
                        <b-button type="is-danger" icon-left="times" @click="isCardModalActive = true">Delete</b-button>
                    </div>
                  </div>                  
                </div>
                <div class="container box">
                    <h3 class="subtitle">HTML Snippet</h3>
                    <b-button v-clipboard:copy="codeSnippet()" @click="codeSnippetCopied()" class="copy-btn is-small" type="is-primary" icon-right="copy">Copy</b-button>
                    <pre class="code-snippet"><code>&lt;iframe src=&quot;https://maps.cartogro.com?id={{getMapConfig().id}}&quot; scrolling=&quot;no&quot; frameborder=&quot;0&quot; height=&quot;{{getMapConfig().widgetHeight + 48}}&quot; width=&quot;100%&quot;&gt;&lt;/iframe&gt;</code>
                    </pre>      
                    
                  </div>

                <div class="container box">
                    <h3 class="subtitle">Link to Share</h3>
                    <b-button v-clipboard:copy="`https://maps.cartogro.com?from=link&id=${getMapConfig().id}`" @click="linkCopied()" class="copy-btn is-small" type="is-primary" icon-right="copy">Copy</b-button>
                    <pre class="code-snippet"><code>https://maps.cartogro.com?from=link&amp;id={{getMapConfig().id}}</code></pre>      
                    
                  </div>

                 <div class="container box">
                   <h3 class="subtitle">Preview</h3>
                   <iframe :src="`https://maps.cartogro.com?id=${getMapConfig().id}&isdemo=true`" scrolling="no" :height="getMapConfig().widgetHeight + 48" width="100%" frameborder="0"></iframe>
                </div>
            </section>
          </div>
</template>

<script>
// @ is an alias to /src

 //import { PostWithAuth, GetWithAuth } from '../services/apiClient';
 import { mapGetters, mapActions } from 'vuex';
 import { GetWithAuth, TrackEvent } from '../services/apiClient';

export default {
  name: 'ViewMap',
  props:{
    mapId: String
  },
  data() {
    return {
      componentKey: false,
      isCardModalActive: false,
      isDeleting: false,
      isPageLoading: true,
      
    }
  },
  beforeRouteEnter (to, from, next) {
      next(vm => vm.setData(to.params.mapId))
    //})
  },
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
      this.setData(to.params.mapId)
      next()
  },
  methods:{
    ...mapGetters(['getMapConfig','getProfileId']),
    ...mapActions(['loadMapConfig']),
    codeSnippet(){return `<iframe src="https://maps.cartogro.com?id=${this.getMapConfig().id}" scrolling="no" frameborder="0" height="${this.getMapConfig().widgetHeight + 48}" width="100%"></iframe>`},
    codeSnippetCopied(){
      this.$buefy.toast.open({
                    message: 'Copied to Clipboard!',
                    type: 'is-success'
                })
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"codeSnippetCopied");
      }
    },
    linkCopied(){
      this.$buefy.toast.open({
                    message: 'Copied to Clipboard!',
                    type: 'is-success'
                })
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"linkCopied");
      }
    },
    setData(mapId){
      this.isPageLoading= true;
      this.loadMapConfig(mapId) // gets from api, bad name
        .then(json => { //finished loading
        this.isPageLoading= false;
        })
        .catch(err => {
          console.log(err);
        })         
    },
    deleteMap(){
      this.isDeleting = true;
      const prof = this.getProfileId();
     // PostWithAuth("https://localhost:44369/api/mapconfigs/update/" + prof.replace('auth0|',''), this.getMapConfig())
      GetWithAuth(`https://api.cartogro.com/api/mapconfigs/delete/${prof.replace('auth0|','')}/${this.getMapConfig().id}`)

        .then(() => {
          this.$emitter.emit('mapUpdated');
          //if(json.isSuccessful){
            this.$buefy.toast.open({
                    message: `Deleted ${this.getMapConfig().name}`,
                    type: 'is-warning'
                })
            this.isCardModalActive = false;
            this.isDeleting = false;
            this.$router.push({ name: 'Home' });
          //}
        })
        .catch(() => {
          this.$emitter.emit('mapUpdated');
          this.$buefy.toast.open({
                    message: `Failed to delete ${this.getMapConfig().name}`,
                    type: 'is-danger'
                })
          this.isCardModalActive = false;
          this.isDeleting = false;
        })
      
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"deletedMap");
      }
    }
  },  
  mounted() {
    const profId = this.getProfileId();
    if(profId){
      TrackEvent(profId.replace('auth0|',''),"viewMapPageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        TrackEvent(profile.id.replace('auth0|',''),"viewMapPageLoad-onEventSet");
      })
    }
  },
  created() {
   
  },
  components: {    
  },
};
</script>

<style scoped>
.copy-btn{
  position: absolute;
  top: 30px;
  right:20px;
}
</style>