<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li class="is-active"><router-link to="/">Portal Home</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Hello, {{ $auth.user.nickname }}
                            </h1>
                            <h2 class="subtitle">
                                Welcome to Cartogro Portal
                            </h2>
                        </div>
                    </div>
                </section><!--

                 <div class="block"/>

                <div class="card message-container">
                <b-message 
                    title="Welcome to Cartogro!" 
                    type="is-info" 
                    has-icon
                    aria-close-label="Hide">
                    <p>Thank you for joining Cartogro!  The  map making portal is still in Beta at the moment, and is currently not optimized for mobiles.  If you encounter any bugs or issues, have any suggestions or feedback, we'd love to hear from you!</p>
                </b-message>
                </div>
-->
                <div class="block"/>

                <div class="card message-container">
                <b-message 
                    title="Welcome to Cartogro!" 
                    type="is-info" 
                    has-icon
                    aria-close-label="Hide">
                    <p>Thank you for joining Cartogro! If you need any assistance, or have any feedback or suggestions, please <router-link to="/contact">contact us</router-link> and we'd be happy to help.</p>
                    <p v-if="!(getProfile().paymentPp)">Payouts are typically made via PayPal.  You can set the PayPal address to recieve commission <router-link to="/payment-info">here</router-link>, alternatively <router-link to="/contact">contact us</router-link> to discuss other options.</p>
                </b-message>
                </div>

                <div class="block"/>

                <section class="info-tiles">
                    <div class="tile is-ancestor has-text-centered">
                        <div class="tile is-parent">
                            <article class="tile is-child box">
                                <p v-if="!showSpinners" class="title">{{referralsThisMonth}}</p>
                                <p v-if="!showSpinners" class="subtitle">Referrals this month</p>
                                <div v-if="showSpinners" style="position: relative; width: 100%; height: 100%; min-height: 90px;"><b-loading v-model="showSpinners" :is-full-page="false"></b-loading>
                            </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child box">
                                <p v-if="!showSpinners" class="title">{{bookingsThisMonth}}</p>
                                <p v-if="!showSpinners" class="subtitle">Bookings this month</p>
                                <div v-if="showSpinners" style="position: relative; width: 100%; height: 100%"><b-loading v-model="showSpinners" :is-full-page="false"></b-loading>
                                </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child box">
                                <p v-if="!showSpinners" class="title">&dollar;{{pendingCommission.toFixed(2)}}</p>
                                <p v-if="!showSpinners" class="subtitle">Pending commission</p>
                                <div v-if="showSpinners" style="position: relative; width: 100%; height: 100%"><b-loading v-model="showSpinners" :is-full-page="false"></b-loading>
                                </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child box">
                                <p v-if="!showSpinners" class="title">&dollar;{{latestPayoutThisMonth.toFixed(2)}}</p>
                                <p v-if="!showSpinners" class="subtitle">Latest payout ({{latestPayoutStatusThisMonth}})</p>
                                <div v-if="showSpinners" style="position: relative; width: 100%; height: 100%"><b-loading v-model="showSpinners" :is-full-page="false"></b-loading>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                <div class="block"/>

                

                <div class="card">
                <!--<header class="card-header">
                    <p class="card-header-title">
                       Thanks for joining Cartogro! 
                    </p>
                    
                </header>-->
                <div class="card-content">
                    <div class="content"><div class="tile is-parent has-text-centered">
                        <h1 class="title tile is-child">
                                Get Started...
                    </h1></div>
                    <div class="tile is-parent">
                            <div class="tile is-child has-text-centered is-4">
                                <div class="subtitle">Start creating your first map widget</div>
                                <div class="get-started-btn-container">
                                <b-button expanded type="is-primary" tag="router-link"  to="/maps/new">Create new map</b-button>
                                </div>
                            </div>
                            <div class="tile is-child has-text-centered is-4">
                                <div class="subtitle">Read our step-by-step guide to getting started</div>
                                <div class="get-started-btn-container">
                                <b-button expanded type="is-primary" tag="a" href="https://www.cartogro.com/documentation" target="_blank">Documentation</b-button>
                                </div>
                            </div>
                            <div class="tile is-child has-text-centered is-4">
                                <div class="subtitle">Need help or answers to any questions</div>
                                <div class="get-started-btn-container">
                                <b-button expanded type="is-primary" tag="router-link"  to="/contact">Contact Us</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
  </div>
</template>


<script>
 import { GetWithAuth, PostWithAuth, TrackEvent } from '../services/apiClient';
  import { mapGetters} from 'vuex';

export default {
  name: 'Summary',
  props:{
  },
  data() {
    return {
        showSpinners: true,
        bookingsThisMonth: null,
        pendingCommission: null,
        latestPayoutThisMonth: null,
        latestPayoutStatusThisMonth: null,
        loadsAllTime: null,
        loadsThisYear: null,
        loadsLastMonth: null,
        loadsThisMonth: null,
        referralsAllTime: null,
        referralsThisYear: null,
        referralsLastMonth: null,
        referralsThisMonth: null
    }
  },
  created(){
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        const prof = profile.id;
        this.getSummaryStats(prof);
        TrackEvent(prof.replace('auth0|',''),"summaryPageLoad-onEventSet");
      })
      const prof = this.getProfileId();
      if(prof){
          this.getSummaryStats(prof);
          TrackEvent(prof.replace('auth0|',''),"summaryPageLoad");
      }
    },
  methods:{
      ...mapGetters(['getProfileId', 'getProfile']),
      getSummaryStats(prof){
          GetWithAuth(`https://api.cartogro.com/api/stats/${prof.replace('auth0|','')}/summary`)

        .then(response => {
            this.bookingsThisMonth = response.bookingsThisMonth;
            this.pendingCommission = response.pendingCommission;
            this.latestPayoutThisMonth = response.latestPayoutThisMonth;
            this.latestPayoutStatusThisMonth = response.latestPayoutStatusThisMonth;

            this.loadsAllTime = response.loadsAllTime;
            this.loadsThisYear = response.loadsThisYear;
            this.loadsThisMonth = response.loadsThisMonth;
            this.loadsLastMonth = response.loadsLastMonth;
            this.referralsAllTime = response.referralsAllTime;
            this.referralsThisYear = response.referralsThisYear;
            this.referralsThisMonth = response.referralsThisMonth;
            this.referralsLastMonth = response.referralsLastMonth;
            this.showSpinners = false;
        })
        .catch(() => {
        })
      }
  },  
  components: {    
  },
};
</script>

<style scoped  lang="scss">
 .get-started-btn-container{
     padding: 0 10px;
 }

 .message-container p {
     margin-top: 8px;
 }

.get-started-btn-container{
    
         @media only screen and (max-width: 478px) {
    margin-bottom: 40px;
  }
 }
</style>
