<template>
  <div>
    <div class="card side-panel">
      <header class="card-header">
        <p class="card-header-title" v-if="isComplete">
          Edit Area
          <button class="button is-danger is-outlined btn-delete" @click="deleteCurrentArea()">
            <span>Delete</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </p>
        <p class="card-header-title" v-if="!isComplete">
          Create Area
          <button class="button is-danger is-outlined btn-delete" v-if="isEditing" @click="deleteCurrentArea()">
            <span>Clear</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="field" v-if="!isComplete">
            <label class="label is-small">Click points on the map to draw the area's outline.  Complete the area by clicking on the start of the path.</label>
            </div>
          </div>
           <div class="field">
            <label class="label is-small">Border style:</label>
              <span class="label is-small colorweight-label">Color:</span>
             <v-swatches v-model="outlineColor" popover-x="left" swatches="text-advanced"></v-swatches>
            <span class="label is-small colorweight-label">Weight:</span>
            <input v-model.number="outlineWeight" type="number" class="input weight-input">
            <label class="label is-small">Fill style:</label>
            <span class="label is-small colorweight-label">Color:</span>
             <v-swatches v-model="fillColor" popover-x="left" swatches="text-advanced"></v-swatches>
            <span class="label is-small colorweight-label">Opacity:</span>
            <input v-model.number="fillOpacity" type="number" class="input weight-input">
             </div>
          </div>
         <!-- <div class="field">
            <label class="label is-small">Description</label>
            <div class="control">
              <textarea class="textarea" v-model="currentEditPin.description" placeholder="Enter a Description"/>
            </div>
          </div>
          <div class="field  is-horizontal">
            <button class="button" @click="editIcon()"><span  v-if="currentEditPin.icon" class="icon">
                <i class="fa" :style="{'color':currentEditPin.color}" v-bind:class='getPinIconClass(currentEditPin.icon)'></i>
              </span><span v-if="currentEditPin.icon">Change Icon</span>
              <span v-if="!currentEditPin.icon">Choose Icon</span></button>
              <span class="label is-small color-label">Color:</span>
            <v-swatches v-model="currentColor" popover-x="left" swatches="text-advanced"></v-swatches>
            </div>
            <div class="field">
              <button class="button" @click="startJourney()"><span class="icon">
                <i class="fa fa-circle"></i>
              </span><span>Start Route</span></button>
              </div>--><button class="modal-close is-large is-danger side-panel-close-btn" @click="closePanel()" aria-label="close"></button>
        </div>
      </div>

</template>

<script>

import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css";
//import { deepClone} from 'lodash';
import { mapMutations } from 'vuex';

export default {
  name: "MapEditorPanelEditArea",
  components: {
    VSwatches 
  },
  props: {
    area: Object,
    isComplete: Boolean,
    isEditing: Boolean
  },
  emit:['updateOutlineColor','updateOutlineWeight','updateFillColor','updateFillWeight','deleteArea'],
  data() {
    return {
      outlineColor: this.area.outlineColor,
      outlineWeight: this.area.outlineWeight,
      fillColor: this.area.fillColor,
      fillOpacity: this.area.fillOpacity,      
    };
  },
  computed: {    
  },
  watch: {
    outlineColor: function (newValue, oldValue) {
     this.updateAreaOutlineColor(newValue);
    },
    outlineWeight: function (newValue, oldValue) {
     this.updateAreaOutlineWeight(newValue);
    },
    fillColor: function (newValue, oldValue) {
     this.updateAreaFillColor(newValue);
    },
    fillOpacity: function (newValue, oldValue) {
     this.updateAreaFillOpacity(newValue);
    },
  },
  methods: {
    ...mapMutations(['updateAreaOutlineColor','updateAreaOutlineWeight','updateAreaFillColor','updateAreaFillOpacity','deleteArea']),
    deleteCurrentArea(){
      this.$emit('deleteArea');
      this.deleteArea();
    },
    closePanel(){
      if(!this.isComplete){
        this.deleteCurrentArea();
      }
      this.$emitter.emit('resetToEditMode');
    }
  }
}
  

</script>


<style scoped>

.side-panel-close-btn{
  position: absolute;
  top: 10px;  
  right: 10px;
}

.side-panel-close-btn::before, .side-panel-close-btn::after {
  background-color: #333333;
}

.side-panel {
  position: absolute;
  width: 30%;
  min-width:280px;
  right: 10px;
  top: 10px;
  max-height: 90%;
  z-index:999;
}
.colorweight-label{
  display:inline-block;
  position: relative;
  margin: 10px;
  top:-15px;
}

.weight-input {
  margin-top: 3px;
  width: 60px;
}

.config-label{
  font-size: 12px;
  text-align:left;
}

.modal {
  z-index: 9999
}

.routetype-btn-container{
  margin-bottom:5px;
}

.btn-delete{
  margin-left: 30px;
}

</style>