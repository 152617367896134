<template>
  <div>
    <div class="card side-panel">
      <header class="card-header">
        <p class="card-header-title">
          {{mode == 'new' ? 'Add new marker' : 'Edit marker'}}
          <button class="button is-danger is-outlined btn-delete" v-if="mode != 'new'" @click="deletePin()">
            <span>Delete</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="field columns">
            <span class="column is-one-half config-label">
              <span class="config-label-title">Latitude: </span>{{currentEditPin.lat}}
            </span>
            <span class="column is-one-half config-label">
              <span class="config-label-title">Longitude: </span>{{currentEditPin.lng}}
            </span>
          </div>
          <div class="field">
            <label class="label is-small">Name</label>
            <div class="control">
              <input class="input" type="text" v-model="currentEditPin.title" placeholder="Enter a Title">
            </div>
          </div>
          <div class="field  is-horizontal">
            <b-button @click="editIcon()" :icon-left="currentEditPin.icon" :style="{'color':currentEditPin.color}">
              <span style="color:black !important;" v-if="currentEditPin.icon">Change Icon</span>
              <span style="color:black !important;" v-if="!currentEditPin.icon">Choose Icon</span>
            </b-button>

            <!--<button class="button" @click="editIcon()"><span  v-if="currentEditPin.icon" class="icon">
                <i class="fa" :style="{'color':currentEditPin.color}" :class='currentIcon'></i>
              </span><span v-if="currentEditPin.icon">Change Icon</span>
              <span v-if="!currentEditPin.icon">Choose Icon</span></button>-->
              <span class="label is-small color-label">Color:</span>
            <v-swatches v-model="currentColor" popover-x="left" swatches="text-advanced"></v-swatches>
            </div>
            
          <div class="field">
            <label class="label is-small">Description</label>
            <div class="control">
              <textarea class="textarea" v-model="currentEditPin.description" placeholder="Enter a Description"/>
            </div>
          </div>
          
        </div>
      </div>
      <footer class="card-footer">
        <a @click="savePin()" class="card-footer-item">Save</a>
        <a @click="cancelPin()" class="card-footer-item">Cancel</a>
      </footer>
      <button class="modal-close is-large is-danger side-panel-close-btn" @click="closePanel()" aria-label="close"></button>
  </div>

  <div class="modal" v-bind:class='{"is-active" : showIconModal}'>
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card">
        <header class="card-header">
    <p class="card-header-title">
      Select Icon
    </p>
  </header>
  <div class="card-content">
    <b-field label="Start typing to search:" style="max-width: 500px;">
            <b-input v-model="iconSearch"></b-input>
        </b-field>
    <div class="content">
      <p class="buttons">
        <b-button :icon-left="icon.id" :disabled="icon.id == currentEditPin.icon" :type="icon.id == currentEditPin.icon ? 'is-primary' : ''" v-for="icon in filteredIcons" :key="icon.id"  @click="selectIcon(icon.id)">
         <!--<span class="icon">{{icon.id}} = {{currentEditPin}} 
                <i class="fa" v-bind:class='getPinIconClass(icon.id)'></i>
              </span>-->
        </b-button>
      </p>
    </div>
    <button class="modal-close is-large is-danger side-panel-close-btn" @click="closePopup()" aria-label="close"></button>
  </div>
</div>
    </div>
   <!--<button class="modal-close is-large" aria-label="close" :click="closePopup()"></button>-->
  
</div>
    
</div></template>

<script>

import { deepClone} from 'lodash';
import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "MapEditorPanelEditPin",
  components: {
    VSwatches 
  },
  props: {
    currentEditPin: Object,
    mode: String
  },
  emit:['updateCurrentEditPin', 'cancelCurrentEditPin', 'updateIcon', 'updateColor', 'deletePin'],
  data() {
    return {
      currentColor: this.currentEditPin.color,
      showIconModal: false,
      iconSearch: '',
      icons: [
              {id: 'circle', name: 'Circle', searchTerms: ['circle','shape']},
              {id: 'star', name: 'Star', searchTerms: ['star','shape']},
              {id: 'heart', name: 'Heart', searchTerms: ['heart','shape','love']},
              {id: 'sun', name: 'Sun', searchTerms: ['sun','weather']},
              {id: 'moon', name: 'Moon', searchTerms: ['moon']},
              {id: 'snowflake', name: 'Snow', searchTerms: ['snowflake','snow','weather','cold','winter','ski']},
              {id: 'binoculars', name: 'Binoculars', searchTerms: ['binoculars','view','viewpoint','sight']},
              {id: 'camera', name: 'Camera', searchTerms: ['camera','view','viewpoint','sight']},
              {id: 'cocktail', name: 'Cocktail', searchTerms: ['cocktail','drink','bar']},
              {id: 'beer', name: 'Beer', searchTerms: ['beer','drink','bar','pub']},
              {id: 'wine-glass-alt', name: 'Wine', searchTerms: ['wine','drink','bar']},
              {id: 'wine-bottle', name: 'Wine Bottle', searchTerms: ['wine','drink','bar']},
              {id: 'glass-cheers', name: 'Cheers', searchTerms: ['cheers','drink','bar']},
              {id: 'coffee', name: 'Coffee', searchTerms: ['coffee','coffeeshop','drink','cafe']},
              {id: 'place-of-worship', name: 'Place of Worship', searchTerms: ['worship','church','building','landmark']},
              {id: 'monument', name: 'Monument', searchTerms: ['monument','landmark','building']},
              {id: 'vihara', name: 'Vihara', searchTerms: ['vihara','monument','landmark','building']},
              {id: 'home', name: 'Home', searchTerms: ['home','house','building','hotel','accomodation']},
              {id: 'hotel', name: 'Hotel', searchTerms: ['home','building','hotel']},
              {id: 'mosque', name: 'Mosque', searchTerms: ['worship','mosque','building','landmark']},
              {id: 'landmark', name: 'Landmark', searchTerms: ['building','landmark']},
              {id: 'archway', name: 'Arch', searchTerms: ['arch','archway','monument','landmark','building']},
              {id: 'hospital', name: 'Hospital', searchTerms: ['clinic','medical','surgery','doctor','hospital','building']},
              {id: 'ambulance', name: 'Ambulance', searchTerms: ['ambulance','doctor','hospital','vehicle']},
              {id: 'clinic-medical', name: 'Clinic', searchTerms: ['clinic','medical','surgery','doctor','hospital','building']},
              {id: 'utensils', name: 'Utensils', searchTerms: ['utensils','knife','fork','food','cafe','restaurant']},
              {id: 'hamburger', name: 'Burger', searchTerms: ['burger','food','cafe','takeaway','restaurant']},
              {id: 'ice-cream', name: 'Icecream', searchTerms: ['icecream','food']},
              {id: 'pizza-slice', name: 'Pizza', searchTerms: ['pizza','food','cafe','takeaway','restaurant']},
              {id: 'campground', name: 'Tent', searchTerms: ['tent','camping','campsite']},
              {id: 'caravan', name: 'Caravan', searchTerms: ['caravan','campervan','motorhome','campsite']},
              {id: 'mountain', name: 'Mountain', searchTerms: ['mountain','nature','hiking','climbing']},
              {id: 'swimmer', name: 'Swimmer', searchTerms: ['swimmer','swimming','bathing','water','sea','lake','activity']},
              {id: 'water', name: 'Water', searchTerms: ['water','sea','lake','ocean','port']},
              {id: 'anchor', name: 'Anchor', searchTerms: ['water','sea','anchor','ocean','ship','port','harbour']},
              {id: 'skull-crossbones', name: 'Skull and Crossbones', searchTerms: ['skull','crossbones','danger','warning']},
              {id: 'tree', name: 'Tree', searchTerms: ['tree','woods','forest','nature','outdoors','park','woodland']},
              {id: 'umbrella-beach', name: 'Beach', searchTerms: ['beach','sea','umbrella']},
              {id: 'info', name: 'Info', searchTerms: ['info']},
              {id: 'torii-gate', name: 'Gate', searchTerms: ['gate','torii','arch','archway','monument','landmark']},
              {id: 'skiing', name: 'Skiing', searchTerms: ['ski','skiing','ski slope','snow','piste','activity']},
              {id: 'shopping-bag', name: 'Shopping', searchTerms: ['shop','shopping','shopping bag','retail','market','store']},
              {id: 'shopping-basket', name: 'Basket', searchTerms: ['shop','shopping','basket','retail','market','supermarket','store']},
              {id: 'shopping-cart', name: 'Supermarket', searchTerms: ['shop','shopping','retail','market','supermarket','store']},
              {id: 'store', name: 'Store', searchTerms: ['shop','shopping','store','retail','market','supermarket']},
              {id: 'gifts', name: 'Gifts', searchTerms: ['gifts','shopping','retail','present','presents','souvenirs']},
              {id: 'money-bill', name: 'Money', searchTerms: ['money','cash','atm','bank']},
              {id: 'theater-masks', name: 'Theatre', searchTerms: ['masks','theatre','play','culture','opera','musical']},
              {id: 'palette', name: 'Palette', searchTerms: ['palette','gallery','culture','art','studio']},
              {id: 'guitar', name: 'Guitar', searchTerms: ['guitar','music','concert','venue']},
              {id: 'compact-disc', name: 'Record', searchTerms: ['record','music','concert','venue','cd']},
              {id: 'music', name: 'Music', searchTerms: ['music','concert','venue','musical']},
              {id: 'film', name: 'Film', searchTerms: ['film','movies','cinema','picturehouse']},
              {id: 'bed', name: 'Bed', searchTerms: ['home','bed','accomodation','sleep','hotel']},
              {id: 'flag', name: 'Flag', searchTerms: ['glag']},
              {id: 'flag-checkered', name: 'Checkered Flag', searchTerms: ['flag','checkered','race','track','circuit']},
              {id: 'walking', name: 'Walking', searchTerms: ['walk', 'walking','hiking','hike','path','footpath']},
              {id: 'hiking', name: 'Hiking', searchTerms: ['walk', 'walking','hiking','hike','path','footpath']},
              {id: 'running', name: 'Running', searchTerms: ['run', 'running','jogging','hike','path']},
              {id: 'motorcycle', name: 'Motorbike', searchTerms: ['bike', 'motorbike','motorcycle','vehicle','transport']},
              {id: 'biking', name: 'Bike', searchTerms: ['bike', 'cycle','bicycle','transport']},
              {id: 'parking', name: 'Parking', searchTerms: ['parking','carpark']},
              {id: 'bus', name: 'Bus', searchTerms: ['bus','station','coach','vehicle','transport']},
              {id: 'car', name: 'Car', searchTerms: ['car','hire','vehicle','transport','garage']},
              {id: 'ship', name: 'Ship', searchTerms: ['ship','sea','harbour','ocean','ferry','port','transport']},
              {id: 'taxi', name: 'taxi', searchTerms: ['taxi','cab','transport']},
              {id: 'subway', name: 'Subway', searchTerms: ['train','subway','station','railway','underground','tube','transport']},
              {id: 'train', name: 'Train', searchTerms: ['train','station','railway','transport']},
              {id: 'shuttle-van', name: 'Shuttle Van', searchTerms: ['bus','station','coach','shuttle','van','vehicle','transport']},
              {id: 'tram', name: 'Tram', searchTerms: ['train','tram','station','transport']},
              {id: 'passport', name: 'Passport', searchTerms: ['passport','embassy']},
              {id: 'fish', name: 'Fish', searchTerms: ['fish','fishing','water','sea', 'animal']},
              {id: 'dog', name: 'Dog', searchTerms: ['dog','park', 'animal']},
              {id: 'horse', name: 'Horse', searchTerms: ['horse', 'animal']},
              {id: 'crow', name: 'Bird', searchTerms: ['bird', 'animal']},
              {id: 'wheelchair', name: 'Wheelchair', searchTerms: ['wheelchair', 'disabled','accessible']},
              {id: 'wifi', name: 'Wifi', searchTerms: ['wifi', 'internet']},
              {id: 'restroom', name: 'Restroom', searchTerms: ['restroom', 'toilet','bathroom','loo']},
              {id: 'spa', name: 'Spa', searchTerms: ['spa','health']},
              {id: 'dice', name: 'Dice', searchTerms: ['dice', 'gamble','gambling','casino','games']},
              {id: 'building', name: 'Building', searchTerms: ['building','city','town']},
              {id: 'city', name: 'City', searchTerms: ['building','city','town','place']},
              {id: 'virus', name: 'Virus', searchTerms: ['virus','covid']},
              {id: 'virus-slash', name: 'no Virus', searchTerms: ['virus','covid']},
              {id: 'head-side-mask', name: 'Facemask', searchTerms: ['virus','covid', 'facemask','mask']},
              {id: 'plane', name: 'Plane', searchTerms: ['plane','airplane', 'aeroplane','airport']},
              {id: 'plane-departure', name: 'Plane Departure', searchTerms: ['plane','airplane', 'aeroplane','airport']},
              {id: 'plane-arrival', name: 'Plane Arrival', searchTerms: ['plane','airplane', 'aeroplane','airport']},
              {id: 'helicopter', name: 'Helicopter', searchTerms: ['helicopter','heliport','chopper']},
              {id: 'phone', name: 'Phone', searchTerms: ['phone','telephone']},
              {id: 'road', name: 'Road', searchTerms: ['road','route','motorway','highway']},
              {id: 'route', name: 'Route', searchTerms: ['road','route']},
              {id: 'shoe-prints', name: 'Shoe Prints', searchTerms: ['path','walk','shoe','footpath','route']},
              {id: 'ticket-alt', name: 'Ticket', searchTerms: ['ticket','event','venue']},
              {id: 'wrench', name: 'Wrench', searchTerms: ['wrench','spanner','tool','repair','garage']},
              {id: 'university', name: 'University', searchTerms: ['university','bank','monument','landmark','building']},
              
              {id: 'dumbbell', name: 'Dumbbell', searchTerms: ['dumbbell','gym','fitness']},
              {id: 'swimming-pool', name: 'Swimming Pool', searchTerms: ['swimming','pool']},
              {id: 'book', name: 'Book', searchTerms: ['book','library','bookshop','reading']},
              {id: 'graduation-cap', name: 'Graduation', searchTerms: ['graduation','university','school','college']},              
              {id: 'futbol', name: 'Soccer', searchTerms: ['football','futbol','soccer','sport','pitch']},
              {id: 'golf-ball', name: 'Golf', searchTerms: ['golf','golfcourse','sport']},
              {id: 'skating', name: 'Skating', searchTerms: ['ice','skate','skating','iceskate','icerink']},
            ]


    };
  },
  computed: {   
    currentIcon: function(){
      const currentIcon = this.currentEditPin.icon;
      return this.getPinIconClass(currentIcon);
    },
    filteredIcons: function(){
      const iconSearch = this.iconSearch;
      return this.icons.filter(icn =>{
        
        return icn.searchTerms.find(s=>{
          return s.indexOf(iconSearch) === 0;
        })
      })
    }
  },
  watch: {
    currentColor: function (newValue, oldValue) {
     this.$emit('updateColor', newValue);
    }
  },
  methods: {
    savePin(){
      const clone = JSON.parse(JSON.stringify(this.currentEditPin));
      this.$emit('updateCurrentEditPin', clone);
    },
    cancelPin(){
      this.$emit('cancelCurrentEditPin');
    },
    editIcon(){
      this.showNewPinAnimation = false;
      this.iconSelected = true;
      this.showIconModal = true;
    },
    getPinIconClass(iconId){
      return `fa-${iconId}`;
    },
    selectIcon(iconId){
      this.currentEditPin.icon = iconId;
      this.showIconModal = false;
      this.$emit('updateIcon', iconId);
    },
    deletePin(){
      this.$emit('deletePin');
    },
    closePanel(){
      this.$emitter.emit('resetToEditMode');
    },
    closePopup(){
      this.showIconModal = false;
    }
  }
}
  

</script>


<style scoped>

  @media only screen and (min-width: 480px) {

.modal .modal-content{
  width: 640px !important;
}
}

  
.side-panel-close-btn{
  position: absolute;
  top: 10px;
  right: 10px;
}

.side-panel-close-btn::before, .side-panel-close-btn::after {
  background-color: #333333;
}

.side-panel {
  position: absolute;
  width: 30%;
  min-width:280px;
  right: 10px;
  top: 10px;
  max-height: 90%;
  z-index:999;
}

.color-label{
  margin: 10px 5px 10px 15px;
}

.config-label{
  font-size: 12px;
  text-align:left;
}

.modal {
  z-index: 9999
}

.btn-delete{
  margin-left: 30px;
}

</style>