<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/contact">Contact Us</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <div>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Contact Us
                            </h1>
                            <h2 class="subtitle">
                                We'd love to hear from you!
                            </h2>
                        </div>
                    </div>
                </section>
                <div class="block"></div>
                <div class="block">
                    For any questions, feedback, or general chit chat, contact us at <strong>contact@cartogro.com</strong>, or use the form below and we'll reply as soon as possible.
                  </div>
                <Contact/>
                </div>

  </div>
</template>


<script>
 //import { PostWithAuth, GetWithAuth } from '../services/apiClient';
 import Contact from '../components/Contact.vue';
  import { TrackEvent } from '../services/apiClient';
   import { mapGetters} from 'vuex';

export default {
  name: 'ContactUs',
  props:{
  },
  data() {
    return {
    }
  },
  methods:{
    ...mapGetters(['getProfileId']),
  },  
  components: {    
      Contact
  },
  mounted(){
    const profId = this.getProfileId();
    if(profId){
      TrackEvent(profId.replace('auth0|',''),"contactUsPageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        TrackEvent(profile.id.replace('auth0|',''),"contactUsPageLoad-onEventSet");
      })
    }
  },
};
</script>

<style scoped>
</style>