<template>
  <!--<div :style="'height: ' + getMapConfig().widgetHeight + 'px; width: 100%'" class="container" v-bind:class="{'current-mode-edit': editMode == 'addPin' || editMode == 'route' || editMode =='area'} ">-->
  <div :style="'height: ' + 600 + 'px; width: 100%'" class="container" v-bind:class="{'current-mode-edit': editMode == 'addPin' || editMode == 'route' || editMode =='area'} ">
    <MapEditorPanelEditPin v-if="editMode == 'addPin' && currentEditPin" 
        :current-edit-pin="currentEditPin"
        :mode="pinEditMode"
        @updateCurrentEditPin="savePin($event)"
        @cancelCurrentEditPin="cancelPin()"
        @updateColor="applyColor($event)"
        @updateIcon="applyIconToNewPin($event)"        
        @deletePin="deleteCurrentPin(currentEditPin)"/>
    <MapEditorPanelHelp  v-if="editMode == 'addPin' && !currentEditPin"
        @cancel="resetToEditMode()"
        title="Add location marker"
        content="Click a location on the map to create a new marker (or click an existing marker to edit)"/>

    <MapEditorPanelEditRoute v-if="editMode == 'route'" 
        :route-type="currentRouteType"
        :route-color="getMapConfig().routeColor"
        :line-weight="getMapConfig().routeLineWeight"
        :route="getMapConfig().route"
        @startRoute="startRoute($event)"
        @deleteRoute="deleteCurrentRoute()"/>

    <MapEditorPanelEditArea v-if="editMode == 'area'" 
        :area="getMapConfig().area"
        :isComplete="hasCompletedArea || !!getMapConfig().area.geoJsonCoordinates"
        :isEditing="hasArea"
        @deleteArea="deleteCurrentArea()"/>
    <l-map
      ref="map"
      :key="getMapConfig().id"
      :zoom="getMapConfig().zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="mapClick($event)"
      :style="{'cursor':(editMode == 'route' && currentRouteType) || (editMode == 'addPin' && !currentEditPin) || editMode=='area' ? 'crosshair' : ''}"
    >
      <l-tile-layer
        ref="layer"
        :url="mapTilesUrl"
        :attribution="attribution"
      />

      <!--pois
      
      <div v-for="feature in filteredPoiFeatures" :key="feature.properties.osm_id">
      <l-marker :lat-lng="getLatLng(feature)">
        <l-icon
          :icon-anchor="[7,8]"
          class-name="someExtraClass"
        >
          <div class="headline">
            O
          </div>
          
        </l-icon>
        <l-popup>
          <div @click="innerClick(feature)">
            <div><strong>{{feature.properties.osm_tags ? feature.properties.osm_tags.name : "x"}} {{feature.properties.osm_id}}</strong></div>
            <div>{{feature}}</div>
          </div>
        </l-popup>
      </l-marker>      
      </div>

      -->

      <div v-for="pin in getMapConfig().pins" :key="pin.id">
      <!-- <l-marker  ref="marker" :lat-lng="getLatLngFromPin(pin)" @click="existingPinClicked(pin)">
         <l-icon
          :icon-anchor="[8,25]"
          class-name="someExtraClass"
        >
          <div class="headline inner-pin-con">
            <div class="inner-pin">
              <span class="icon custom-icon">
                <i class="fa" :style="{'color':pin.color}" v-bind:class='getIconClass(pin.icon)'></i>
              </span>
            </div>
                <div class='pin' :style="{'background':pin.color}" >
                  
                </div>
              </div>
          </l-icon>
        <l-popup>
          <div>
            <div><strong>{{pin.title}}</strong></div>
            <div>{{pin.description}}</div>
            <div>{{pin.id}}</div>
            <div>{{pin.osmId}}</div>
            <div>{{pin.osmCategories}}</div>
            <button class="button" @click="editPin(pin)">Edit</button>
          </div>
        </l-popup>
      </l-marker>       -->
        <map-marker
        :latLng="getLatLngFromPin(pin)"
        :draggable="false"
        :animate="false"
        :showIcon="true"
        :showTitle="false"
        :color="pin.color"
        :icon="pin.icon"
        :showPulse="pin == currentEditPin"
        :title="pin.title"
        :description="pin.description"
        class="config-pins-marker"
        @pinClick="editPin(pin)"
        />
      </div>
      <map-marker v-if="currentNewPinLatLng"
      :latLng="currentNewPinLatLng"
      :draggable="false"
      :animate="showNewPinAnimation"
      :showIcon="showIconInEditPin"
      :showTitle="false"
      :color="currentEditPin.color"
      :icon="currentEditPin.icon"
      :showPulse="true"
      :title="currentEditPin.title"
      :description="currentEditPin.description"
      />
      <!--<l-marker v-if="currentNewPinLatLng" :lat-lng="currentNewPinLatLng" :draggable=true>
        
         <l-icon
          :icon-anchor="[8,25]"
          class-name="someExtraClass"
        >
          <div class="headline inner-pin-con">
            <div class="inner-pin">
              <span class="icon custom-icon" v-bind:class='{hide : !showIconInEditPin}'>
                <i class="fa" :style="{'color':currentEditPin.color}" v-bind:class='getCurrentPinInconClass'></i>
              </span>
            </div>
                <div class='pin' :style="{'background':currentEditPin.color}" v-bind:class='{pinAdding : showNewPinAnimation}'>
                  
                </div>
    <div class='pulse'></div>
              </div>
          </l-icon>          
        
      </l-marker>   -->

      <l-marker v-if="currentRoutePulse" :lat-lng="currentRoutePulse" :draggable=true>
         <l-icon
          :icon-anchor="[8,25]"
          class-name="someExtraClass"
        >
          <div class="headline inner-pin-con">
    <div class='pulse'></div>
              </div>
          </l-icon>          
        
      </l-marker>

      <l-marker v-if="currentAreaStartPoint" :lat-lng="currentAreaStartPoint" @click="completeAreaPolygon()">
         <l-icon
          :icon-anchor="[10,10]"
          class-name="someExtraClass"
        >
          <div class="headline inner-pin-con">
            <div class="invisible-spot">O</div>
            
              </div>
          </l-icon>          
        
      </l-marker>      

      <!--https://vue2-leaflet.netlify.app/components/LGeoJson.html#demo-->
      <!--https://leafletjs.com/reference-1.0.3.html#path-->
      
      <l-geo-json v-for="(item, index) in getRoute" :geojson="item.geoJson ? item.geoJson : item.geoJsonLine" :key="`journeyleg-${index}`"  :options-style="routeStyles" :componentKey="componentKey"></l-geo-json>

      <l-geo-json v-for="(item, index) in getLineRoute" :geojson="item.geoJson ? item.geoJson : item.geoJsonLine" :key="`linejourneyleg-${index}`"  :options-style="routeStyles" :componentKey="componentKey"></l-geo-json>

      <l-geo-json v-if="hasArea && areaGeoJson" :geojson="areaGeoJson" :options-style="areaStyles" ></l-geo-json>
<!--
      <l-geo-json :geojson="getMapConfig().area.geoJsonCoordinates" :options-style="areaStyles" ></l-geo-json>-->
    </l-map>
    <a href="https://www.cartogro.com" class="map-logo"><img style="width: 80px"
                    src="../../assets/logo-border.svg"
                    alt="Cartogro.com"
                ></a>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon, LGeoJson } from "vue2-leaflet";
import { v4 as uuidv4 } from 'uuid';
import MapEditorPanelEditPin from './MapEditorPanelEditPin';
import MapEditorPanelEditRoute from './MapEditorPanelEditRoute';
import MapEditorPanelEditArea from './MapEditorPanelEditArea';
import MapEditorPanelHelp from './MapEditorPanelHelp';
import MapMarker from './MapMarker';
import _ from 'lodash';
 import { mapGetters, mapMutations } from 'vuex';
  import { TrackEvent } from '../../services/apiClient';
// import openrouteservice from 'openrouteservice-js';


async function getData(url = '') {
  // Default options are marked with *
  const response = await fetch(url);
  console.log(response);
  return response.json(); // parses JSON response into native JavaScript objects
}

export default {
  name: "MapEditorMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
 //   LPopup,
    LIcon,
    LGeoJson,
    MapEditorPanelEditPin,
    MapEditorPanelEditRoute,
    MapEditorPanelEditArea,
    MapEditorPanelHelp,
    MapMarker
  },
  props: {
  //  mapConfig: Object,
    mapSearchLocation: Object,
    editMode: String,
    componentKey: Boolean
  },
  emit:['updateBounds'],
  data() {
    return {
      //center: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributers | &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>',
      withPopup: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      withTooltip: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      currentCenter: latLng(this.getMapConfig().lat, this.getMapConfig().lng),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      mapBounds: null,
      currentEditPin: null,
      currentNewPinLatLng: null,
    //  editMode: 'addPin',
      iconSelected: false,
      showNewPinAnimation: true,
      pinInPlace: false,
      pinEditMode:'new',
      currentEditPinColor: '#000000',
      currentRoutePulse: null,
      currentRouteType: null,
      areaPaths: [],
      currentAreaStartPoint: null,
      hasCompletedArea: false,
      hasAreaEditing: false
    };
  },
  watch: {
    editMode: function (newValue, oldValue) {
      if(newValue == 'edit'){
        this.cancelPin();
      }
     
    },
    mapSearchLocation: function (newValue, oldValue) {
      this.$refs.map.mapObject.setView([newValue.lat, newValue.lng], newValue.zoom);     
    },
  },
  mounted(){
    this.currentRouteType = this.getMapConfig().routeType ? {key: this.getMapConfig().routeType } : null;
    this.mapBoundsUpdate();
  },
  computed: {    
    center: function(){
        return latLng(this.getMapConfig().lat, this.getMapConfig().lng)
      },
    hasArea: function(){
      return this.hasAreaEditing || !!this.getMapConfig()?.area?.geoJsonCoordinates;
    },
    areaGeoJson: function(){
      if(this.hasArea && this.getMapConfig().area.geoJsonCoordinates){
        return {
          "type": "Polygon",
             "coordinates": [this.getMapConfig().area.geoJsonCoordinates]
        }
      }

      if(this.hasArea && this.currentAreaStartPoint){
        return {
             "type": "LineString",
             "coordinates": this.areaPaths
            }
      }
      return null;    
      
    },
    getRoute: function(){
      return this.getMapConfig().route != null 
        ? this.getMapConfig().route.filter(x=>x.geoJson != null || x.geoJsonLine != null)
        : [];
    },
    getLineRoute: function(){
      return this.getMapConfig().lineRoute != null 
        ? this.getMapConfig().lineRoute.filter(x=>x.geoJson != null || x.geoJsonLine != null)
        : [];
    },
    mapTilesUrl: function(){
      if(this.getMapConfig().mapStyle == 'Standard'){
        return 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Street'){
        return 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Satellite'){
        return 'https://api.maptiler.com/tiles/satellite-mediumres-2018/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Outdoor'){
        return 'https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Topographic'){
        return 'https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Winter'){
        //return 'https://api.maptiler.com/maps/winter/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
        return 'https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Bright'){
        return 'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Pastel'){
        return 'https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=XQkcBPA5hzBYzx1MW9M8';
      }
      if(this.getMapConfig().mapStyle == 'Hybrid'){
        return 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=XQkcBPA5hzBYzx1MW9M8';
      }
      return 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    },
    //filteredPoiFeatures: function(){
    //  if(!this.mapConfig.pois.features){
    //    return [];
    //  }
    //  if(!this.mapConfig.pins || this.mapConfig.pins.length == 0){
    //    return this.mapConfig.pois.features;
    //  }

    //  let filteredFeatures = this.mapConfig.pois.features.filter(x =>{
    //    return !this.mapConfig.pins.find(y => {
    //      return y.osmId == x.properties.osm_id 
    //      }
    //      );
    //  });
      
   //   return filteredFeatures;
   // },
    showIconInEditPin: function(){
      return this.currentEditPin.icon && this.pinInPlace; //this. added, not sure what this does
    },
    getCurrentPinInconClass: function(){
      return this.getIconClass(this.currentEditPin.icon);
    },
    routeStyles: function() {
      const fillColor = this.getMapConfig().routeColor; //??? important! need touch fillColor in computed for re-calculate when change fillColor
      const weight = this.getMapConfig().routeLineWeight;
      return () => {
        return {
          weight: weight,
          color: fillColor,
         // opacity: 1,
         // fillColor: fillColor,
         // fillOpacity: 1
        };
      };
    },
    areaStyles: function() {
      const outlineColor = this.getMapConfig().area.outlineColor; //??? important! need touch fillColor in computed for re-calculate when change fillColor
      const outlineWeight = this.getMapConfig().area.outlineWeight;
      const fillColor = this.getMapConfig().area.fillColor;
      const fillOpacity = this.getMapConfig().area.fillOpacity;
      return () => {
        return {
          weight: outlineWeight,
          color: outlineColor,
         // opacity: 1,
          fillColor: fillColor,
          fillOpacity: fillOpacity
        };
      };
    },
  },  
  methods: {
    ...mapGetters(['getMapConfig', 'getProfileId']),
    ...mapMutations(['deleteArea', 'addArea', 'deleteRoute', 'addRouteLeg', 'addRouteLineLeg', 'updateZoom', 'updatePosition', 'deletePin', 'setPin', 'setRouteType']),
    getIconClass(iconId){
      return `fa-${iconId}`;
    },
    //existingPinClicked(pin){
    //  this.clearNewPin();
    //  if(this.editMode === 'route' && this.currentRouteType){
    //    this.applyRoutePoint(pin);      
    //  }      
    //},
    clearNewPin(){
      this.currentNewPinLatLng = null;
      this.showNewPinAnimation = true;
      this.pinInPlace = false;
      this.currentRoutePulse = null;
      this.currentEditPin = null;
    },
    mapClick(event){
            
      const pin = {
        poiFeature: null,
        id: uuidv4(),
        osmId: null,
        title: "",
        description: "",
        lat: event.latlng.lat,
        lng: event.latlng.lng,
        osmCategories: null,
        icon: null,
        color: '#000000'
      };
      console.log(pin);
  if(this.editMode === 'addPin'){
    this.currentRoutePulse = null; //shouldnt be pulsing if in this mode
    if(this.currentEditPin){//to move pin?not sure works      
    // this.showNewPinAnimation = false;      
    //  this.currentEditPin.lat = event.latlng.lat;
    //  this.currentEditPin.lng = event.latlng.lng;
    const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-addingNewPinWithCurrentEditPin");
      }
    }
    else{
      this.pinEditMode = 'new';
      this.currentEditPin = pin;

      console.log(pin);
      this.currentEditPin.icon = 'circle';
      
      this.currentNewPinLatLng = latLng(event.latlng.lat,event.latlng.lng);

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-addingNewPin");
      }
    }
    this.pinInPlace = false;
    
        
        
        
        setTimeout(function(){
          
          this.showNewPinAnimation = false;
          this.pinInPlace = true;
        }, 1000);

  }

    if(this.editMode === 'route' && this.currentRouteType){
        this.applyRoutePoint(pin);      
        const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-addingRoutePoint");
      }
      }

    if(this.editMode === 'area'){
        this.applyAreaPoint(pin);      
        const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-addingAreaPoint");
      }
      }

    
    //}

    },
    applyRoutePoint(pin){
      //if(this.editMode === 'route' && this.currentRouteType){
      this.setRouteType(this.currentRouteType.key);
      if(this.getMapConfig().route?.length == 0 && this.getMapConfig().lineRoute?.length == 0){
        
        
        this.currentRoutePulse = latLng(pin.lat, pin.lng);
        if(this.currentRouteType.key == "line"){
        this.addRouteLineLeg({geoJson: null, lat: pin.lat, lng: pin.lng});
        }
        else{
          this.addRouteLeg({geoJson: null, lat: pin.lat, lng: pin.lng});
        }
      }
      else{
        if(this.getMapConfig().route.length == 1 || this.getMapConfig().lineRoute.length == 1){
          if(this.currentRouteType.key == "line"){
            const data = {
                    "type": "LineString",
                    "coordinates": [[this.getMapConfig().lineRoute[0].lng, this.getMapConfig().lineRoute[0].lat], [pin.lng, pin.lat]]
                }
                this.currentRoutePulse = latLng(pin.lat, pin.lng);
                this.addRouteLineLeg({geoJson: data, lat: pin.lat, lng: pin.lng});
          }
          else{
            const url = `https://api.openrouteservice.org/v2/directions/${this.currentRouteType.key}?api_key=5b3ce3597851110001cf624811a4640ee65d489d9867d81b82cd32d4&start=${this.getMapConfig().route[0].lng},${this.getMapConfig().route[0].lat}&end=${pin.lng},${pin.lat}`;
            getData(url).then(data => {
                //this.journey.push({geoJson: data, lat: pin.lat, lng: pin.lng});
                if(!data.error){
                    this.currentRoutePulse = latLng(pin.lat, pin.lng);
                    this.addRouteLeg({geoJson: data, lat: pin.lat, lng: pin.lng});
                }
                else{
                  this.$buefy.toast.open({
                    message: 'Unable to set waypoint here - make sure it is accessible for the select route type.',
                    type: 'is-warning'
                })
                }
                
              });
          }
        
        }
        else{
          if(this.currentRouteType.key == "line"){
            const lastPoint = this.getMapConfig().lineRoute[this.getMapConfig().lineRoute.length - 1];
            const data = {
                    "type": "LineString",
                    "coordinates": [[lastPoint.lng, lastPoint.lat], [pin.lng, pin.lat]]
                }
                this.currentRoutePulse = latLng(pin.lat, pin.lng);
                this.addRouteLineLeg({geoJson: data, lat: pin.lat, lng: pin.lng});
          }
          else{
            const lastPoint = this.getMapConfig().route[this.getMapConfig().route.length - 1];
            const url = `https://api.openrouteservice.org/v2/directions/${this.currentRouteType.key}?api_key=5b3ce3597851110001cf624811a4640ee65d489d9867d81b82cd32d4&start=${lastPoint.lng},${lastPoint.lat}&end=${pin.lng},${pin.lat}`;
            getData(url).then(data => {
                if(!data.error){
                  this.currentRoutePulse = latLng(pin.lat, pin.lng);
                    this.addRouteLeg({geoJson: data, lat: pin.lat, lng: pin.lng});
                }
                else{
                  this.$buefy.toast.open({
                    message: 'Unable to set waypoint here - make sure it is accessible for the selected route type.',
                    type: 'is-warning'
                })
                }
                
              //this.journey.push({geoJson: data, lat: pin.lat, lng: pin.lng});
            });
          }
        }
      //}
    }

    },
    applyAreaPoint(pin){
      this.hasAreaEditing = true;
      //if(this.editMode === 'route' && this.currentRouteType){
      if(!this.getMapConfig().area.geoJsonCoordinates){
        this.currentRoutePulse = latLng(pin.lat, pin.lng);
        if(this.areaPaths.length == 0){
          this.currentAreaStartPoint = latLng(pin.lat, pin.lng)
        };
        this.areaPaths.push([pin.lng, pin.lat]);
        //this.$emit('addRouteLeg', {geoJson: null, lat: pin.lat, lng: pin.lng});
      }
      
    

    },
    completeAreaPolygon(){
      this.areaPaths.push([this.currentAreaStartPoint.lng, this.currentAreaStartPoint.lat]);
      this.currentRoutePulse = null;
        this.currentAreaStartPoint = null;
        this.hasCompletedArea = true;
        this.addArea(this.areaPaths);
    },
    startJourney(){
      //this.journeyStart = {lat: this.currentEditPin.lat, lng: this.currentEditPin.lng, pin: this.currentEditPin};
      //this.$emit('addRouteLeg', {geoJson: null, lat: this.currentEditPin.lat, lng: this.currentEditPin.lng});
     // this.cancelPin();
    },
    getLatLng(poi){
      return latLng(poi.geometry.coordinates[1], poi.geometry.coordinates[0]);
    },
     getLatLngFromPin(pin){
      return latLng(pin.lat, pin.lng);
    },
    zoomUpdate(zoom) {
      this.updateZoom(zoom);
      this.mapBoundsUpdate();      
    },
    centerUpdate(center) {
      console.log(center);
      this.updatePosition(center);
      
    },
    mapHasPopup(){
      (!!this.$refs.map && !!this.$refs.map.mapObject && !!this.$refs.map.mapObject._popup);
    },
    mapBoundsUpdate(){    
      this.mapBounds = this.$refs.map.mapObject.getBounds();
      this.$emit('updateBounds',this.mapBounds);
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    savePin(data){
      this.setPin(data);
      this.clearNewPin();
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-savingPin");
      }
    },
    cancelPin(){
      this.clearNewPin();
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-cancellingPinEdit");
      }
    },
    applyIconToNewPin(data){
      this.currentEditPin.icon = data;
      this.showNewPinAnimation = false;
      this.iconSelected = true;
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-applyingIconToPin");
      }
    },
    applyColor(data){
      this.showNewPinAnimation = false;
      this.pinInPlace = true;
      this.currentEditPin.color = data;
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-applyingIconColor");
      }
    },
    resetToEditMode(){
      this.clearNewPin();
      this.$emitter.emit('resetToEditMode');
    },
    innerClick(feature) {
      //used?
      //console.log(event.rangeParent);
      //var feature = JSON.parse(event.rangeParent.toString().replace('#text ',''));
      const pin = {
        poiFeature: feature,
        id: uuidv4(),
        osmId: feature.properties.osm_id,
        title: feature.properties.osm_tags.name,
        description: "",
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
        osmCategories: feature.properties.category_ids
      };
      this.currentEditPin = pin;
      //this.$emit('updateBounds',this.mapBounds);
      // alert("Click!");
      
    },
    editPin(pin){
      this.clearNewPin();
      
      if(this.editMode === 'route' && this.currentRouteType){
        this.applyRoutePoint(pin);      
      }      
      else{
        this.pinEditMode = 'edit';
        this.currentEditPin = pin;     
        
        if(this.editMode === 'edit'){
          this.$emitter.emit('resetToAddPinMode');
        }
      }

      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-pinEditing");
      }
      
      //let refs = this.$refs;
      //console.log(refs.map.mapObject);
      
        //refs.map.closePopup();
    },
    deleteCurrentPin(pin){ 
      this.deletePin(pin.id);
      this.clearNewPin();
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-currentPinDeleting");
      }
    },
    deleteCurrentArea(){
      //this.deleteArea();      
      this.currentAreaStartPoint = null;
      this.currentRoutePulse = null;
      this.hasCompletedArea = false;
      this.areaPaths = [];
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-currentAreaDeleting");
      }
    },
    deleteCurrentRoute(){ 
      this.currentRoutePulse = null;
      this.currentRouteType = null;
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-currentRouteDeleting");
      }
    },
    startRoute(routeType){
      //this.$emit('startedRoute', routeType);
      this.currentRouteType = routeType;
      const profId = this.getProfileId();
      if(profId){
        TrackEvent(profId.replace('auth0|',''),"mapeditor-startRoute");
      }
    },
    
  }
}
  

</script>


<style scoped>

.map-logo{
  position: absolute;
  bottom: 5px;
  left: 10px;
  z-index: 999;
}

.inner-pin
{
  position: absolute;
  top: -22px;
  left:2px;
  font-size: 20px;  
  z-index: 9;
}


.pin {
  cursor: move;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #333333;
/*  position: absolute;*/
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -8px;
  
  z-index: 998;  
}

.invisible-spot{
  visibility:hidden;
}
.config-label{
  font-size: 12px;
  text-align:left;
}
.hide{
  display: none;
}
.pinAdding{
    animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  }
  .custom-icon{
    font-size: 15px;
    margin-left: -7px;
  }
 .pin:after {
    content: '';
    width: 26px;
    height: 26px;
    margin: 2px 0 0 -13px;
    background: #FFFFFF;
    position: absolute;
    border-radius: 50%;
 }  
.pulse {
  cursor: move;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -6px;
  transform: rotateX(55deg);
  z-index: -2;
}
 .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    box-shadow: 0 0 1px 2px #89849b;
    animation-delay: 1.1s;
 }
@keyframes pulsate{
  0%{
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50%{
    opacity: 1.0;
  }
  100%{
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
  

@keyframes bounce
{
  0%{
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }
  60%{
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }
  80%{
    transform: translateY(-10px) rotate(-45deg);
  }
  100%{
    transform: translateY(0) rotate(-45deg);
  }
}

</style>

<style>
.current-mode-edit .leaflet-interactive{
  cursor: crosshair !important;
}

.leaflet-marker-icon,  .current-mode-edit .leaflet-marker-icon{
  cursor: pointer !important;
}
</style>