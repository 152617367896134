<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/">Cartogro</a></li>
                        <li><router-link to="/">Portal Home</router-link></li>
                        <li class="is-active"><router-link to="/contact">Bookings</router-link></li>
                        <!--<li><a href="../">Templates</a></li>
                        <li><a href="../">Examples</a></li>
                        <li class="is-active"><a href="#" aria-current="page">Admin</a></li>-->
                    </ul>
                </nav>
                <div>
                <section class="hero is-info welcome is-small  is-brand-color">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Bookings
                            </h1>
                            <h2 class="subtitle">
                                Individual bookings detail
                            </h2>
                        </div>
                    </div>
                </section>
                <div class="block"></div>
                <div class="block">
                    Bookings can take several days before registering.  Commission amounts shown are for indication purposes only.  Actual values will finalized when commission slip is generated. 
                  </div>
                  
                <div class="block">
                  <b-table style="font-size: 12px;" :data="bookings" :columns="columns"></b-table>
                  </div>
                </div>

  </div>
</template>


<script>
 import { GetWithAuth, TrackEvent } from '../services/apiClient';
   import { mapGetters} from 'vuex';
    import date from 'date-and-time';
// import Contact from '../components/Contact.vue'

export default {
  name: 'Bookings',
  props:{
  },
  data() {
    return {
      bookings: [],
      columns: [
        {
            field: 'bookingDate',
            label: 'Booking Date',
           // centered: true
        },
        {
            field: 'bookingDate',
            label: 'Booking Date'
        },
        {
            field: 'expectedPaymentMonth',
            label: 'Confirmation Month'
        },
        {
            field: 'checkinDate',
            label: 'Checkin'
        },
        {
            field: 'checkoutDate',
            label: 'Checkout'
        },
        {
            field: 'bookingStatus',
            label: 'Booking Status'
        },
        {
            field: 'payoutStatus',
            label: 'Payout Status'
        },
        {
            field: 'commissionValue',
            label: 'Commssion'
        },
        {
            field: 'description',
            label: 'Description'
        },  
      ]
    }
  },
  mounted(){
    const profId = this.getProfileId();
    if(profId){
      this.getData(profId);
      TrackEvent(profId.replace('auth0|',''),"bookingsPageLoad");
    }
    else{
      this.$emitter.on(
      'profileSetGlobal', (profile) => {
        this.getData(profile.id);
        TrackEvent(profile.id.replace('auth0|',''),"bookingsPageLoad-onEventSet");
      })
    }
  },
  methods:{
    getData(prof){
      GetWithAuth(`https://api.cartogro.com/api/stats/${prof.replace('auth0|','')}/bookings`)
        .then(response => {
          response.bookings.forEach(x=>{            
            x.bookingDate = this.formatDate(x.bookingDate); 
            x.expectedPaymentMonth = this.formatDate(x.expectedPaymentMonth); 
            x.checkinDate = this.formatDate(x.checkinDate); 
            x.checkoutDate = this.formatDate(x.checkoutDate); 
            x.bookingStatus = this.formatBookingStatus(x.bookingStatus);
            x.payoutStatus = this.formatPayoutStatus(x.payoutStatus);
            x.commissionValue = "$" + x.commissionValue.toFixed(2);
          })
          this.bookings = response.bookings;
        })
        .catch(() => {
        })
    },
    ...mapGetters(['getProfileId']),
    formatDate(dat){
      return date.format(new Date(dat), 'ddd, MMM DD YYYY');
    },
    formatBookingStatus(status){
      if(status === 0){
        return 'Detected';
      }
      if(status === 1){
        return 'Pending';
      }
      else if(status === 2){
        return 'Not Cancelled';
      }
      else if(status === 3){
        return 'Cancelled';
      }
      else if(status === 4){
        return 'Completed';
      }
      else if(status === 5){
        return 'Paid';
      }
      return '';
    },
    formatPayoutStatus(status){
      if(status === 0){
        return '';
      }
      else if(status === 1){
        return 'Unconfirmed';
      }
      else if(status === 2){
        return 'Pending';
      }
      else if(status === 3){
        return 'Processing';
      }
      else if(status === 4){
        return 'Paid';
      }
      else if(status === 5){
        return 'Due';
      }
      else return '-'
    },
  },  
  components: {    
  },
};
</script>

<style scoped>
</style>