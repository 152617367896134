<template>
  <b-tooltip :label="text" multilined position="is-left" style="color: #fff; background: #9400FF; width: 20px; height: 20px; border-radius: 10px; padding: 0 7px; font-weight: bold; line-height: 1.2; position: absolute; top: 5px; right: 5px; cursor: help;">?</b-tooltip> 
</template>


<script>

export default {
  name: 'Tooltip',
  props:{
    text: String
  },
  
};
</script>

<style scoped>
</style>