<template>
  <div>
    <div class="card side-panel"><button class="modal-close is-large is-danger side-panel-close-btn" @click="closePanel()" aria-label="close"></button>
      <header class="card-header">
        <p class="card-header-title" v-if="routeType">
          Edit Route
          <button class="button is-danger is-outlined btn-delete" v-if="mode != 'new'" @click="deleteCurrentRoute()">
            <span>Delete</span>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </p>
        <p class="card-header-title" v-if="!routeType">
          Create a New Route
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="field" v-if="!routeType">
            <label class="label is-small">Start a new route:</label>
            <div v-for="rt in routeTypes" :key="rt.key" class="control routetype-btn-container">
              <button class="button is-fullwidth" @click="startRoute(rt)"><span class="icon">
                <i class="fa" :class="getIconClass(rt.icon)"></i>
              </span><span>{{rt.text}}</span></button>
            </div>
          </div>
           <div class="field" v-if="routeType">
            <label class="label is-small" v-if="!route || route.length == 0">Click the route start position on the map</label>
            <label class="label is-small" v-if="route.length > 0">Click the map to continue the {{routeType.text}} route</label>
              <span class="label is-small colorweight-label">Color:</span>
             <v-swatches v-model="color" popover-x="left" swatches="text-advanced"></v-swatches>
            <span class="label is-small colorweight-label">Weight:</span>
            <input v-model.number="lineWeight" type="number" class="input weight-input">
             </div>
          </div>
         <!-- <div class="field">
            <label class="label is-small">Description</label>
            <div class="control">
              <textarea class="textarea" v-model="currentEditPin.description" placeholder="Enter a Description"/>
            </div>
          </div>
          <div class="field  is-horizontal">
            <button class="button" @click="editIcon()"><span  v-if="currentEditPin.icon" class="icon">
                <i class="fa" :style="{'color':currentEditPin.color}" v-bind:class='getPinIconClass(currentEditPin.icon)'></i>
              </span><span v-if="currentEditPin.icon">Change Icon</span>
              <span v-if="!currentEditPin.icon">Choose Icon</span></button>
              <span class="label is-small color-label">Color:</span>
            <v-swatches v-model="currentColor" popover-x="left" swatches="text-advanced"></v-swatches>
            </div>
            <div class="field">
              <button class="button" @click="startJourney()"><span class="icon">
                <i class="fa fa-circle"></i>
              </span><span>Start Route</span></button>
              </div>-->
        </div>
      </div>
  </div>

  
    </template>

<script>

import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css";
 import { mapMutations } from 'vuex';

export default {
  name: "MapEditorPanelEditRoute",
  components: {
    VSwatches 
  },
  props: {
    routeType: Object,
    routeColor: String,
    lineWeight: Number,
    route: Array
  },
  emit:['updateRouteColor','updateLineWeight', 'startRoute', 'deleteRoute'],
  data() {
    return {
      color: this.routeColor,
      routeTypes: [
        {
          key: 'line',
          text: 'Straight Lines',
          icon: 'pen'
        }, 
        {
          key: 'driving-car',
          text: 'Driving',
          icon: 'car'
        },              
        {
          key: 'foot-walking',
          text: 'Walking',
          icon: 'walking'
        },
        {
          key: 'foot-hiking',
          text: 'Hiking',
          icon: 'hiking'
        },
        {
          key: 'cycling-regular',
          text: 'Cycling',
          icon: 'bicycle'
        },
    //    {
    //      key: 'cycling-road',
    //      text: 'Cycling (road)',
    //      icon: 'bicycle'
    //    },
    //    {
    //      key: 'cycling-mountain',
    //      text: 'Cycling (mountain)',
    //      icon: 'bicycle'
    //    },
    //    {
    //      key: 'cycling-electric',
    //      text: 'Cycling (electric)',
    //      icon: 'bicycle'
    //    },
     //   {
     //     key: 'driving-hgv',
    //      text: 'HGV',
   //       icon: 'caravan'
     //   },
    //    {
      //    key: 'wheelchair',
        //  text: 'Wheelchair',
      //    icon: 'wheelchair'
     //   },
      ]
    };
  },
  computed: {    
  },
  watch: {
    color: function (newValue, oldValue) {
     this.updateRouteColor(newValue);
    },
    lineWeight: function (newValue, oldValue) {
     this.updateRouteLineWeight(newValue);
    },
  },
  methods: {
    ...mapMutations(['deleteRoute','updateRouteColor','updateRouteLineWeight']),
    startRoute(routeType){      
      this.$emit('startRoute', routeType); // just sets the route type - vuex it
    },
    getIconClass(iconId){
      return `fa-${iconId}`;
    },
    deleteCurrentRoute(){
      this.$emit('deleteRoute');
      this.deleteRoute();
    },
    closePanel(){
      this.$emitter.emit('resetToEditMode');
    }
  }
}
  

</script>


<style scoped>


.side-panel-close-btn{
  position: absolute;
  top: 10px;
  right: 10px;
}

.side-panel-close-btn::before, .side-panel-close-btn::after {
  background-color: #333333;
}

.side-panel {
  position: absolute;
  width: 30%;
  min-width:280px;
  right: 10px;
  top: 10px;
  max-height: 90%;
  z-index:999;
}

.colorweight-label{
  display:inline-block;
  position: relative;
  margin: 10px;
  top:-15px;
}

.weight-input {
  margin-top: 3px;
  width: 60px;
}

.config-label{
  font-size: 12px;
  text-align:left;
}

.modal {
  z-index: 9999
}

.routetype-btn-container{
  margin-bottom:5px;
}


.btn-delete{
  margin-left: 30px;
}

</style>